.wrapper{
    width: 980px;
    margin: 70px auto;
    border: 1px solid black;
    font-family: "Times New Roman";
    font-size: 12px;
    display: flex;
    flex-direction: column;
}
.wrapper input {
    font-family: "Times New Roman";
    font-size: 12px;
}
.cap{
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: max-content;
    float: right;
    margin-left: auto;
    margin-right: 0;
}
.cap span, .nameCap div {
    font-weight: bold;
}

.nameCap {
    display: flex;
    flex-direction: column;
}
.nameCap input {
   border: none;
}
.membersList{
    display:flex;
    flex-direction: column;
}
.title{
    font-weight: bold;
    margin: 44px auto 30px auto;
    text-align: center;
}
.text {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: baseline;
    width: 90%;
    margin: 0 auto;
    text-indent: 40px;
}
.text span:first-child{
    padding-left: 45px;
    width: 60%;
}
.text input, .members input{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid gray;
    padding-left: 10px;
    padding-right: 10px;
}
.text input:hover, .members input:hover{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid gray;
    padding-left: 10px;
    padding-right: 10px;
}
.countDoc {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid gray;
    width: 5%;
    margin-right: 10px;
}
.docs {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid gray;
    width:87%;
    margin-right: 10px;
}
.listDocs ul {
    width: 80%;
    margin: 15px auto;
}
.members{
    width: 460px;
    margin: 30px auto;
}
.members div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.addBtn {
    margin-top: 30px;
    float: right;
    border: 1px solid gray;
}
.date {
    display: flex;
    align-items: baseline;
}
