.registration-container{
    display: flex;
    flex-direction: column;
    margin: 10px 20%;
}

.registration-container h3{
    border-bottom: solid 2px black;
    line-height: 24px;
    padding-top: 5px;
    
}
.logo {
    width: 85px;
    margin: 20px auto;
    display: block;
}
.registr-box{
    margin: 15px 0px;
}

.registr-box-radio{
    margin: 8px 0px;
}

/*.registr-input{*/
/*    width: 450px;*/
/*}*/

.registr-input-2{
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.registr-input-2:before{
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
}
.emp-t{
    color:red !important;
}
.registr-input-2:after{
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
}

form{
    max-width: 100%;
    margin: 0px auto 0;
}

.btn-save{
    -webkit-font-smoothing: antialiased;
border: 0;
cursor: pointer;
margin: 0;
display: inline-flex;
outline: 0;
position: relative;
align-items: center;
user-select: none;
vertical-align: middle;
justify-content: center;
text-decoration: none;
-webkit-tap-highlight-color: transparent;
padding: 6px 16px;
font-size: 0.875rem;
min-width: 64px;
box-sizing: border-box;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 500;
line-height: 1.75;
border-radius: 4px;
letter-spacing: 0.02857em;
text-transform: uppercase;
box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
color: #fff;
background-color: #3f51b5;

}

.btn-save-pdf{
    -webkit-font-smoothing: antialiased;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    color: rgb(7, 7, 7);
    background-color: #dadada;
    margin: 5px 20px;
}

.btn-save-wrapper_{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.company-checked{
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}
.imgLogo{
    width: 86px;
    margin: 12px auto 20px auto;
    display: block;
}
.container_registration{
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
}
