.letter{
    width: 100%;
    border: 1px solid black;
    height: auto;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
}

.header{
    display: grid;
    grid-template-columns: 2fr 1fr;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    margin-top: 30px;
}

.underHeader{
    font-weight: 100;
}

.description {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.number-input{
    border: none;
    border-bottom: 1px solid black;
    width: 120px;
    font-size: 12px;
}

.description-child{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
}

.description-child > div {
    padding-left: 10px;
}

.description-text{
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
}

.text-decor{
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
}

.table{
    margin: 10px 10px;
}

.table-header{
    font-weight: bold;
    font-size: 10px;
}

.footer_{
 display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 40px auto;
}
.name_styles{
    display: flex;
    flex-direction: column;
}
.tg  {
    border-collapse:collapse;
    border-spacing:4px;
    width: 100%
}
  
.tg td{ 
    border-color:black; 
    border-style:solid; 
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:12px;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
}
  
.tg th{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:12px;
    font-weight:normal;
    overflow:hidden;
    padding:5px;
    word-break:normal;
}
  
.tg .tg-1{border-color:inherit;font-size:12px;text-align:center;vertical-align:center;font-weight: bold}
.tg-2{border-color:inherit;font-size:12px;text-align:center;vertical-align:center;}


.toolbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


.plan-letters{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}
.letter th{
    vertical-align: middle!important;
}
.warnMessage{
    font-weight: bold;
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}