.icon:hover .delete_icon{
    visibility: initial; 
}

.delete_icon{
    visibility: hidden;
    cursor: pointer;
}

#root .container .MuiTableContainer-root table tbody td.MuiTableCell-root.MuiTableCell-body.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 53px;    
}