.btn-letter{
    display: flex;
    flex-direction: row;
    margin: 20px;
    width: 460px;
}

.btn-wrapp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.columns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.title_letters{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #E7B41B;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top:-133px;
    text-align: center;
    flex-wrap: wrap;
    margin-bottom: 42px;
}
