.wrapLetter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    margin: 0 auto;
}
.wrapLetter button {
    width: 243px;
    height: 63px;
    margin: 30px auto;
}
.wrapLetter span {
    font-weight: bold;
    font-size: 18px;
}
