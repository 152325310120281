.envelope-container{
    border: 1px solid black;
    font-weight: 600;
    height: 740px;
    width: 1000px;
    margin: 199px auto 10px auto;

}
.title_letters_{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #E7B41B;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 43px;
    text-align: center;
    flex-wrap: wrap;
}
.envelope-box1{
    margin: 10% 7% 15% 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.wrapper_address{
    display: flex;
    flex-direction: column;
}
.envelope-box2{
    display: flex;
    flex-direction: column;
    margin: 4% auto 10% 65%;
    padding-top: 7%;
}

.postageStamp{
    border-right: 1px dotted black;
    border-bottom: 1px dotted black;
    height: 72px;
    width: 51px;

}
.logo-wrap-img{
    height: 76px;
    width: 190px;
margin-bottom: 10px;
}
.refactor-address{
    font-size: 12px;
    color: darkgray;
    cursor: pointer;
    margin-top: 20px;
}
.wrap-refactor-address-inp{
    display: flex;
    flex-direction: column;
}
.wrap-refactor-address-inp input{
    margin-bottom: 10px;
    border: 1px solid gray;
    border-radius: 4px;
}
.wrap-refactor-address-inp input::placeholder{
    padding: 10px;
}
