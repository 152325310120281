.officesToolbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.field-letters{
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

.officesToolbar .box-filter{
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.officesToolbar .box-filter p{
    padding-right: 5px;
    
}
