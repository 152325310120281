.company-link{
    margin: 10px;
    font-size: 26px;
    width: 200px;
    height: 100px;
    background: rgba(218, 216, 253, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: solid 1px white;
    padding: 0px 10px;
    position: relative;
}
.wrap-clear-user{
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
}
.err{
    background: #dc9a9a !important;
}
.user-eventlog{
    width: 20px;
    cursor: pointer;
}

.company-link:hover, .company-link-org:hover{
    background: rgba(178, 176, 207, 0.4);
    border: solid 1px black;
}

.company-wrapp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2%;
    flex-wrap: wrap;
}

.buttonNew{
    display: flex;
    justify-content: flex-end;
}
.title_company_added {
font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #E7B41B;
    display: flex;
    justify-content: center;
}
.title_cabinet_ {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: black;
    display: flex;
    justify-content: center;
    margin: 280px auto 0 auto;
}
.company-grid-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.company-grid-container_ {
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-flow: dense;
}
.pre-adm-title{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: black;
    display: flex;
    justify-content: center;
    margin: 11px auto 22px auto;
}
.container_admin {
    width: 98%;
    display: flex;
    flex-direction: column;
   margin-right: auto;
    margin-left: auto;
}

.btnDeleteCompany{
    margin: 0px 20px;
}

.span-company-link{
    font-size: 16px;
    text-align: center;
    font-weight: 100;
    margin: 10px;
    height: 80px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteIcon{
    position: absolute;
    visibility: hidden;
    top: 0;
    right: 0;
}

.company-link:hover .deleteIcon, .company-link-org:hover .deleteIcon{
    visibility: inherit;
}
.company_wrap-admin{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.container_users-admin{
    width: 44%;
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.wrapper-admin-organizations table tr , .wrapper-admin-organizations table td{
    border: 1px solid gray;
 }
.wrapper-admin-organizations table {
    text-align: center;
    margin-top: 11px;
}
.company-link-org {
    margin: 10px;
    font-size: 17px;
    width: 140px;
    height: 80px;
    background: rgba(218, 216, 253, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: solid 1px white;
    padding: 0px 10px;
    position: relative;
}
.org-btn{
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
    padding: 6px 16px;
    font-size: 10px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}
.title-table-org{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-size: 12px;
}
.loading{
    margin: 0 auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: black;
    display: flex;
    justify-content: center;
}
th {
    padding-left: 10px!important;
}
.btn-table{
    background: aliceblue;
    padding: 6px 16px;
    font-size: 14px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    border: none;
    cursor: pointer;
}
.btn-table:disabled{
    background: gray;
    cursor: auto;
}
.comp-link{
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list-search li{
    list-style-type: none;
    cursor: pointer;
}
.list-search {
    margin-left: 0px;
    margin-top: 10px;
    border: 1px solid #f1f0f0;
    width: max-content;
    height: max-content;
    background: #f1f0f0;
    border-radius: 3px;
    padding: 10px;
}
.image_clear{
    width: 15px;
    position: relative;
    left: -15px;
    top: -24px;
    cursor: pointer;
}
.event-log-list{
   margin-bottom: 30px;
}
.wrapper-added-user{
    display: flex;
    flex-direction: column;
}
.wrapper-added-user input {
    border-top: none;
    border-right: none;
    border-left: none;
    margin-bottom: 15px;
    padding: 10px;
}
.wrapper-added-user select {
    border-top: none;
    border-right: none;
    border-left: none;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-bottom-style: solid;
    border-bottom-width: 1px;
}
.wrapper-added-user input::placeholder {
    color: black!important;
}
.comp-wrap-dialog .MuiDialog-paperWidthSm {
    max-width: 725px!important;
}
.wrap-user-block-title{
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #f35959;
}
.wrapper-added-user input ::placeholder{
    color:gray;
}
.wrapPeriods {
    margin-bottom: 40px;
    display: flex;
    min-width: 350px;
    justify-content: space-between;
}
.wrapPeriods button:active{
     background: rgb(220, 154, 154);
}
.wrapperUniquePeriodEvents{
    display: flex;
    height: 177px;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 10px;
}
.inp_1-period{
    margin-left: 19px;
    margin-right: 10px;
    border: 1px solid gray;
    border-radius: 10px;
}
.inp_2-period {
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid gray;
    border-radius: 10px;
}
.wrapperUniquePeriodEvents button{
    width: 86px;
}
.wrapperBtnsEv{
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
}
.wrapTitleEvents{
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}