.toolbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;  
}
.wrapper-deleted-tables th, .wrapper-deleted-tables td{
    border: 1px solid darkgray;
    padding: 10px;
}

