.container {
    width: 98%;
    margin: 30px auto;
}

.paper {
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    padding: 4%;
    font-size: 18px;
}

.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 5px;
    font-size: 16px;
}

.boxChild {
    margin:  0 10px;
    width: 30%;
    display: flex;
}

.boxChildNum {
    margin:  0 10px;
    width: auto;
}

.boxChildInputMd{
    width: 50%;
}

.headers {
    text-align: center;
    padding-bottom: 15px;
}

.fullName{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

h3{
    padding-top: 40px;
}

.inputSmall{
    width: 35px;
}

.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.boxChildInput{
    width: 60%;
    display: flex;
    align-items: center;
}

.militaryInput{
    width: 50%;
}

.passport{
    margin:  0 10px;
    padding-right: 17%;
}

.passportChild{
    margin:  0 3px;
}

.diplomInput{
    margin:  0 3px;
    width: 28%;
    text-align: right;
}

.inputBoxThree{
    width: 100%;
}

.boxButton{
    display: flex;
    margin: 25px;
    justify-content: center;

}

.button{
    margin: 0 20px;
}

.select{
    width: 100%;
}

.boxSelectInput{
    display: flex;
    flex-direction: row;
}

.pSelectInput{
    margin: 0 10px;
}

.selectInput{
   width: 75%;
}

.buttonBack{
    position: fixed;
    left : 30px;
    top: 45px;
}

.buttonLoaded{
    margin: 20px 100px 0px 100px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.toolbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.contentEditable {
    height: 30px;
    border-bottom: 1px solid black;
    width: 90%;
}

.contentEditable:target  {
    height: 30px;
    border-top: 1px solid red;
    width: 90%;
}

.contentEditable:hover > *[contenteditable="true"] {
    background-color: rgba(217, 245, 255,0.5);
  }

.familyInput{
    border: none;
    border-bottom: 1px solid black;
    font-size: 16px;
    line-height: 22px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 60%;
    display: flex;
    align-items: center;
}


