.wrapper{
    margin: 0 auto;
    border: 1px solid black;
    width: 794px;
    height: 512px;
}
.title{
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 9px;
}
.title_{
    text-align: center;
    font-weight: 700;
}
.form {
    width: 91%;
    margin: 50px auto;
}
.formInput{
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
}
.formInput input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid black;
}
.formInput span {
    text-align: center;
    font-size: 14px;
}
.wrapperTable{
    width: 794px;
    height: 1123px;
    margin: 50px auto;
}
.wrapperTable table, .wrapperTable table td, .Card_2 , .Card_2 td, .Card_2 tr {
    border: 3px solid black!important;
}
.wrapperTable table{
  font-weight: bold;
}
.wrapperTable table tr td {
text-align: center;
}
.wrapperTable table tr td:first-child{
    width: 81px;
}
.wrapperTable table tr td:nth-child(2){
    width: 50px;
}
.wrapperTable table tr td:nth-child(4){
    width: 80px;
}
.wrapperTable table tr td:nth-child(3){
    width: 583px;
}
.wrapperTable table tr:first-child{
    height: 50px;
}
.wrapperTable table tr:nth-child(2){
    height: 27px;
}
.wrapperTable table tr:nth-child(3), .Card_2 tr:nth-child(5){
    height: 1300px;
    background: linear-gradient( #000, transparent 1px), linear-gradient(90deg, #000, transparent 1px);
    background-size: 20px 20px;
    background-position: center center;
}
.btn {
    margin: 55px auto!important;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}


.card {
    width: 794px;
    border: 1px solid black;
    margin: 0px auto 70px auto;
    height: 500px;
}
.cardTitle {
    text-align: center;
    margin: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.cardTitle span {
    font-weight: 700;
    font-size: 30px;
}
.cardTitleInput {
    display: flex;
    flex-direction: column;
}

.cardTitleInput input {
    width: 90%;
    margin-top: 20px;
    margin-left: 40px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid black;
}
.cardTitleInput span {
    text-align: center;
    font-size: 13px;
}

.wrapper_{
    width: 794px;
    margin: 0px auto;
    height: 1123px;
}

.wrapperTitleInput{
    display: flex;
    flex-direction: column;
}


.wrapperTitleInput input{
    border-top:none;
    border-right:none;
    border-left:none;
    width: 80%;
    display: block;
    margin: 0 auto;
}


.wrapperTitleInput span{
    text-align: center;
    font-size: 11px;
}


.Card_2{
    width: 794px;
    margin-top: 30px;
    margin-left:0px;
}

.Card_2 td{
    border: 1px solid black;
    text-align: center;
    font-weight: 700;


}
.Card_2 input{
    width: 90%;
    border: none;
}


@media print {
    .wrapperTable, .wrapTablePrint {
        page-break-before: always;
    }
}
