.arrearItem{
   /* height: 20px;*/
    padding: 15px;  
    margin: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.arrearRed{
    background: rgba(207, 43, 43, 0.5)
}

.arrearYellow{
    background: white
}

.arrearRed-danger{
    background: rgba(185, 3, 3, 0.7)
}
.arrearOrange{
    background: orange;
}
.arrearBlue{
    background: #8686eb;
 }