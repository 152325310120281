.container{
    font-family: Rubik;
}
.wrap_resp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wrap_resp_btn{
    color: white;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #111B06;
    border: none;
    padding: 9px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 0px;
}
.home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    width: 43%;
    flex-wrap: wrap;
    margin: 130px auto 20px auto;
}
.home-categories {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    width: 90%;
    flex-wrap: wrap;
    margin: 4px auto 20px auto;
    height: 545px;
}
.btn{
    margin: 15px auto 50px auto;
    width: 93%;
    height: max-content;
    white-space: break-spaces;

}
#root > div > div.home > div:nth-child(1) > a > button, #root > div > div.home > div:nth-child(2) > a > button,
#root > div > div.home > div:nth-child(3) > a > button, #root > div > div.home > div:nth-child(4) > a > button,
#root > div > div.home > div:nth-child(5) > a > button, #root > div > div.home > div:nth-child(6) > a > button,
#root > div > div.home > div:nth-child(7) > a > button, #root > div > div.home > div:nth-child(8) > a > button{
    padding:17px
}

.link{
    text-decoration: none;
}

.responsible{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.responsibleLink{
    border-bottom: 1px solid black;
    cursor: pointer;
    margin-left: 7px;
}

.home-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: 20px;
}

.respBtn{
    margin-left: 7px;
    text-decoration: none;
}
@media (max-width: 768px) {
    .home{
width: 100%;
    }
    .MuiButton-label{
        font-size: 12px;
    }
    .container{
        margin: 0 auto;
    }

}
.title-cabinet span{
    text-align: center;
    margin: 48px 5px;
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: black;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.title-cabinet{
    margin-top: 30px;
    width: 100%;
    background: white;
    display: flex;
    justify-content: center;
}
.btn_{
    width: 300px;
    height: 100px;
    margin-bottom: 35px;
}
.first-section, .second-section, .third-section{
    display: flex;
    flex-direction: column;
    height: 550px;
    justify-content: flex-start;
}
@media (max-width:1199px) and (min-width: 992px) {
    .home-categories{
        width: 100%;
    }
}
@media (max-width: 992px) {
    .home-categories{
        width: 100%;
    }
}