body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.container_{
  width: 43%!important;
  height: max-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.container_militaryBase{
  height: max-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
._container_{
  margin: 20px auto;
  width: 97%!important;
  height: max-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.wrap_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.wrap_list_{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.search{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0px 20px;
}

.active-rank{
 background: gray;
  border: none;
  margin-right: 5px;
  border-radius: 5px;
  height: 46px;
}

.rank-button{
  border: 1px solid black;
  margin-right: 5px;
  background: white;
  border-radius: 5px;
  height: 46px;
}
.MuiPaper-elevation2{
  width: 100%;
}

.MuiDialog-paperWidthXl.MuiDialog-paperScrollBody{
  min-height: 75vh;
}


.tg  {
  border-collapse:collapse;
  border-spacing:4px;
  width: 100%
}

.tg td{
  border-color:black;
  border-style:solid;
  border-width:1px;
  font-family:Arial, sans-serif;
  font-size:14px;
  overflow:hidden;
  padding:10px 5px;
  word-break:normal;
}

.tg th{
  border-color:black;
  border-style:solid;
  border-width:1px;
  font-family:Arial, sans-serif;
  font-size:14px;
  font-weight:normal;
  overflow:hidden;
  padding:5px;
  word-break:normal;
}

.tg .tg-lboi{border-color:inherit;text-align:center;font-size:14px;vertical-align:center}
.tg .tg-0pky{border-color:inherit;text-align:center;font-size:14px;vertical-align:center}
.tg .tg-zd5i{border-color:inherit;font-size:14px;text-align:center;vertical-align:center}
.tg .tg-0lax{border-color:inherit;font-size:14px;text-align:center;vertical-align:center}
.tg .tg-1{border-color:inherit;font-size:12px;text-align:center;vertical-align:center;font-weight: bold}
.tg-2{border-color:inherit;font-size:12px;text-align:center;vertical-align:center;}

.tg .tg-1lax{border-color:inherit;font-size:12px;text-align:left;vertical-align:center; font-family:'Times New Roman', Times, serif;}
.tg .tg-2lax{border-color:inherit;font-size:12px;text-align:center;vertical-align:center; font-family:'Times New Roman', Times, serif;}


.file-uploader {
  width: 300px;
  height: 215px;
  border: 2px solid steelblue;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
}
.wrapper-dates-data{
  display: flex;
  width: 90%;
  justify-content: space-around;
}
.file-uploader--dragging {
  background-color: lightblue;
}

.file-uploader__contents {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-uploader__file-name {
  font-weight: 700;
  padding-bottom: 15px;
}


.rec .rec-swipable{
  min-height: 90vh;
}

body {
  margin: 0;
  padding: 0;
  /*height: 100vh;*/
}

a {
  color: #666;
}

h1 {
  font-weight: bold;
  margin: 0 0 10px;
}

h4 {
  margin-bottom: 30px;
}

h1,
h2,
h4 {
  text-align: center;
}

.App-payment {
  padding: 30px;
}

form {
  margin: 30px auto 0;
  max-width: 400px;
}

.form-actions {
  margin-top: 15px;
}

.App-cards {
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
}

.App-cards-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-cards-list > * {
  transform: scale(0.8);
  margin-bottom: 30px !important;
}

.App-highlight {
  font-family: serif;
  margin: 15px auto 0;
  max-width: 300px;
}

.App-highlight > div {
  padding-left: 40px;
}

.App-badges {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-badges a {
  display: block;
}

.App-badges a + a {
  margin-top: 18px;
}

.github__btn {
  align-items: center;
  background-color: #ccc;
  border-radius: 4px;
  color: #444;
  display: flex;
  line-height: 1;
  padding: 8px 12px;
}

.github__btn:hover {
  color: #444;
  text-decoration: none;
}

.github__btn img {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
}

.App-credits {
  background-color: #000;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.App-credits a {
  color: #fff;
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .App-badges {
    flex-direction: row;
  }

  .App-badges a + a {
    margin-top: 0;
    margin-left: 18px;
  }
}



.btn-createReport{
  margin-bottom: 10px;
}

.wrapp-btn-createReport{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 77px 10px 10px 10px;
}

.btn-createReport-container{
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}



.nameSystem{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.makeStyles-paper-1{
  margin-top: 0px;
}

.nameSystem p{
  font-size: 22px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  margin-top: 10px;
}

.check{
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.check > label{
  padding-left: 5px;
}


.btn-wrapper{
  position: fixed;
  left : 30px;
  top: 16px;
  z-index: 10;
}

.buttonLoaded{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 5%;
}

.militaryBase-toolbar{
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.title_military_base {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #E7B41B;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 10px;
}
.title_military_base_list {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #E7B41B;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 54px;
  text-align: center;
  margin-bottom: -50px;
  position: fixed;
  z-index: 22222;
  background: white;
  padding: 6px;
  width: 98%;
}
.wrap-choiced-comp{
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-star{
  font-size: 14px;
}
.login-star-error{
  font-size: 16px;
  color: red;
}
.regulations-star{
  font-size: 14px;
  margin-left: 20px;
}

.documents-box1{
  max-height: 20vh;
}

.documents-box2{
  max-height: 80vh;
  margin: 0 auto;
  width: 98%;
}

.table{
  width: 99%;
}


#root > div > div.documents-box2 > div:nth-child(2) > div.MuiPaper-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded > div.MuiTableContainer-root.makeStyles-container-2 > table > tbody > tr:nth-child(1) > td:nth-child(2){
  display: contents;
}
.iconNotification-wrapp{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 50px;
  cursor: pointer;
}

.iconNotification{
  border-radius: 50%;
  padding: 5px;
}

.iconNotification:hover{
  background: rgba(202, 202, 202, 0.3);
}
.title_{
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #E7B41B;
  width: 100%;
 margin: 60px auto -61px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wrap-report-6-btns{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.wrap-report-6-btns div{
  display: flex;
  flex-direction: column;
}
.icon-event {
  width: 21px;
  position: absolute;
  top: 79px;
  right: 5px;
}
.letter-columns-td td{
  vertical-align: middle;
}


@media (max-width: 670px) and (min-width: 570px){
  .imakzv{
    top: 67px;
  }
}
@media (max-width: 569px) and (min-width: 470px){
  .imakzv{
    top: 138px;
  }
}
@media (max-width: 469px) {
  .imakzv{
    top: 217px;
  }
}
/*@media (min-width: 1228px) {*/
/*  .MuiDialog-paperWidthSm {*/
/*    min-width: 1200px !important;*/
/*  }*/
/*}*/
/*@media (max-width: 1228px) {*/
/*  .MuiDialog-paperWidthSm {*/
/*    max-width: 725px!important;*/
/*  }*/
/*}*/
.MuiDialog-paperFullScreen {
  width: 85% !important;
  height: 90% !important;
}