.App {
  text-align: center;
}
html{
  scroll-behavior: smooth;
  height: 100%;
}
@media (min-width: 320px) and (max-width: 360px){
  body {
    width: 100%;
  }
}
@media (min-width: 361px) and (max-width: 450px){
   body {
     width: 100%;
   }
}
@media (min-width: 451px) and (max-width: 750px){
  body {
    width: 100%;
  }
}
/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/


/*.title{*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  font-size: 22px;*/
/*  font-weight: 500;*/
/* */
/*}*/

