.arrowIcon{
    padding: 3px;
    color: rgb(134, 145, 155);
    visibility: initial;
}

.arrowIcon-not-visible{
    padding: 3px;
    color: rgb(134, 145, 155);
    visibility: hidden;
}

.arrowIcon:hover{
    color: black;
    cursor: pointer;
}


.wrapp-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover{
    background: rgba(218, 215, 215, 0.3)!important;
}
.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected{
    /*background: white!important;*/
}

.toolbar-buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    visibility: hidden;
}

.toolbar-buttons-visible{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    visibility: initial;
}

.wrapp-body-settingsModal {
    display: flex;
}


.MuiTableCell-body{
    width: auto!important;
}