.personalCard2-container {
    margin: 30px auto;
}

.personalCard2-box{
    display: flex;
    flex-direction: row;
    margin: 10px;
    align-items: center;
}

.personalCard2-table{
    font-size: 18px;
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.personalCard2-table .header-table {
   /* border: 2px solid black;*/
    font-size: 20px;
   /* margin: 5px;*/
}
td {
    word-wrap: break-word
}

.personalCard2-table > th, .personalCard2-table td{
    border: 1px solid black;
}

.personalCard2-table td{
    padding: 5px 5px;
}

.personalCard2-table th{
    height: 40px;
    border: 2px solid black;
}
.wrap-notice{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}
.personalCard2-table .data-left{
   /* width: 50%;*/
}

.personalCard2-input-date::before {
    /*width: 50%;*/
    /*border: none!important;*/
} 

.personalCard2-input{
    width: 100%;
    padding: 1px 3px;
}

.personalCard2-input::before{
    border: none!important;
}

.personalCard2-secondInput::before{
    border-bottom: 1px solid black;
}
/*
.personalCard2-input #grade, .personalCard2-input #date_remove_military, .personalCard2-input #grade,{
    border-bottom: 1px solid black!important;
}
*/
.personalCard2-input::before .second{
    border-bottom: 1px solid black!important;
}

.personalCard2-input-second{
    width: 100%;
    padding: 1px 3px;
}

.cell-bold{
    text-align: center;
    font-weight: bold;
}

.textarrea-personalCard2{
    width: 99%;
    height: 99%;
    margin: 2px;
    border: none;
    font-size: 16px;
   /* padding: 5px;*/
}

.personalCard2-autocomplete{
    margin: 5px 0px;
    border: none;
}

.personalCard2-autocomplete input{
    border: none;
}

.personalCard2-input-small{
    width: 150px;
    padding: 0px 5px;
    margin: 0px 2px;
}

.date-wrapp{
    margin: 0px 0px 0px 10px;
    display: inline-block;
}

.mySelect{
    width: 99%;
    height: 35px;
    font-size: 16px;
    border: none;
}

.mySelect option{
    width: 99%;
    height: 40px;
    font-size: 16px;
    border: none;
}

.mySecondSelect{
    width: 99%;
    height: 35px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid black;
}

.mySecondSelect option{
    width: 99%;
    height: 40px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid black;
}

select:focus /*.personalCard2-container textarea:focus*/{
    border: none!important;
    outline: none!important;
}

.wrapper-table-personalCard2{
    margin-bottom: 10px;
}

#root > div > div > form > div > table > tbody > tr:nth-child(40) > td > div > div > div.Component-horizontalScrollContainer-12 > div > div > div > table > tbody > tr:nth-child(1) > td:nth-child(3) > div > div > input{
    width: 500px!important;
}

#root > div > div > form > div > table > tbody > tr:nth-child(38) > td > div > div > div.Component-horizontalScrollContainer-12 > div > div > div > table > tbody input{
    width: 950px!important;
}
.container_1{
    width: 95%;
    height: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
}
