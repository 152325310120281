.wrapper{
    width: 100%;
    height: auto;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
}

.headerReport{
    display: grid;
    grid-template-columns: 2fr 1fr;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    margin-top: 5px;
}

.descriptionReport{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.descriptionReport-text{
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}

.descriptionReport-child{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
}

.name-input{
    border: none;
    border-bottom: 1px solid black;
    width: 200px;
    font-size: 14px;
    line-height: 16px;
    height: 18px;
}

.footerReport{
    display: grid;
    grid-template-columns: 3fr 1fr ;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 14px;
    width: 60%;
}
.footerReport_{
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    font-size: 14px;
    width: 60%;
}

.footerReportForm6{
    display: grid;
    grid-template-columns: 1.5fr 1fr ;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    font-size: 14px;
}

.p-report{
    text-decoration: underline;
    text-align: center;
    font-size: 14px;
}

.descriptionReport-child{
    display: flex;
    flex-direction: column;
}

.descriptionReport-child div{
    font-weight: 100;
    margin: 5px 0px;
}

.descriptionReport-child div input{
    margin: 0px 5px;
}

.numberReport-input{
    width: 130px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;
}

.number-input-small{
    width: 70px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;
}

.vertical{
    -webkit-transform: rotate(-90deg); 
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  filter:flipH flipV;
}
.report-set{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    color: red;
}