.name{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
}

.name p{ 
    margin-right: 10px;
}

.input-name{
    border: none;
    border-bottom: 1px solid black;
    width: 180px;
    font-size: 14px;
    line-height: 20px;
    height: 18px;
}