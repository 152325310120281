
.tableAllUser .MuiTableRow-root:hover { 
  background: rgba(42, 42, 145, 0.4) !important;
  
}
.tableAllUser{
  /*position: absolute;*/
  width: 100%;
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}
.fullName{
  color: white;
  cursor: pointer;
}
.wrap-again-send {
text-align: center;
}
.wrap-again-send button{
  cursor: pointer;
  border: none;
  margin-top: 10px;
}
.clear-search-box{
  width: 15px;
  margin-right: 16px;
  cursor: pointer;
}
.filterAge{
  width: 60px;
}

.formControl{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.formControl-right{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: 150px;
}

.formControl-right-regulation{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap:wrap;
}

.age{
  margin: 0 10px;
}

.wrapp-age{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
