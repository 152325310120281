.wrapperResp{
    margin: 40px auto;
    border: 1px solid black;
    height: auto;
    font-family: Times,sans-serif;
    width: 800px;
}

.name-input{
    border: none;
    border-bottom: 1px solid black;
    width: 200px;
    font-size: 14px;
    line-height: 16px;
    height: 18px;
    background: rgba(160, 157, 157, 0.55)
}

.name-input-footer{
    border: none;
    border-bottom: 1px solid black;
    width: 150px;
    font-size: 14px;
    line-height: 16px;
    height: 18px;
    background: rgba(160, 157, 157, 0.55)
}

.header{
    display: grid;
    grid-template-columns: 2fr 1fr;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-top: 60px;
}

.wrapp-header{
    display: flex;
    flex-direction: column;
    
}

.body-functionalResp{
    margin: 20px 50px;;
    font-size: 18px;
}

.body span{
    font-weight: bold;
    padding-bottom: 20px;
}

.body-title-functionalResp{
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
}

.footerResp{
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}

.footer-child{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin: 20px 0px;
    align-items: flex-end;
}

.body-text p{
    margin-block-start: 0em;
    margin-block-end: 0em;
    text-indent: 30px;
    text-align: justify;
}

.btn-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.plan-functional {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    
}

.btn-pdf{
    margin: 0px 10px;
}
