.h4 {
    text-align: end;
    margin: 8px;
}

.box2 {
    margin-top: 35px;
}
.titleFlagDate:hover{
    cursor: pointer;
    text-decoration: underline;
}
.title {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #E7B41B;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 89px;
    text-align: center;
}

.p {
    text-decoration: underline;
    text-align: center;
}

.toolbar {
    display: flex;
    flex-direction: row
}

.body {
    display: flex;
    flex-direction: column;
}

.fileUpload {
    margin: 30px 0;
    display: flex;
    justify-content: center;
}

.btn {
    display: flex;
    justify-content: flex-end;
    margin: 15px;
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.autocomplete {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 5px;
    font-size: 16px;
}

.boxChild {
    margin: 0 15px;
    display: flex;
}

.select {
    width: 100%;
}


.boxChildInput {
    width: 60%;
    display: flex;
}


.p_autocomplete {
    padding: 0 10px;
}


.menuAutocomplete {
    position: fixed;
    z-index: 200;
    background: rgb(255, 255, 255);
    cursor: pointer;
}


.btnUpdate {
    margin-left: 10px;
    height: 30px;
    text-decoration: none;
    cursor: pointer
}

.date {
    padding: 0 10px;
    font-size: 14px;
}

.forOffices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 25px;
    margin-top: 20px;
}

.error {
    font-size: 16px;
    text-align: center;
}
