
.landing_body li {
    list-style: none;

}
.paid_logos_mobile{
    display:none;
    background: rgba(255, 255, 255, 0.6);
    mix-blend-mode: normal;
    border-radius:6px;
}
.landing_body {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
}

.menu_wrapper_mobile, .burger-menu-mobile {
    display: none;
}
.go-to-registration-organization{
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #C49B14;
}
.go-to-registration-organization_btnc{ 
    margin-top: 45px;
    height: 60px;
    width: 410px;
    border-radius: 4px;
    background: #DBAA14;
    align-items: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    color:white;
    padding-top: 20px;
    
}

.go-to-registration-organization_btnc:focus,.go-to-registration-organization_btnc:hover{ 
    color:white;
}
.go-to-registration-organization:hover{
    color: #C49B14;
}
.mode_list_registration{
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: black;

}
.mode_list_registration:hover{
    
    color: green;

}

.landing_links a:hover {
    color: rgba(240, 186, 20, 1) !important;
    text-decoration: underline !important;
}

.footer_title:hover {
    color: rgba(240, 186, 20, 1) !important;
}

.btn-signin {
    color: white;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    margin-right: 10px;
    border-bottom: 3px solid black;
}

.btn-signin:hover {
    border-bottom: 3px solid #fcac45;
}


nav {
    width: 100%;
    position: relative;
}

.topnav a {
    color: white;
    text-align: center;
    padding: 6px 14px;
    font-size: 16px;
    font-weight: bold;
    display: none;
    border-bottom: 3px solid black;
}

.topnav a.icon {
    float: right;
    display: block;
    color: white;
    font-size: 18px;
    margin-right: 10px;
    border-bottom: 3px solid black;
    font-weight: 400;
    display: flex;
    align-items: baseline;
    cursor: pointer;
}

.topnav a:hover {
    border-bottom: 3px solid #fcac45 !important;
}

.top_block_addresses_contacts {
    display: none;
}

.topnav .icon {
    display: none;
}

.topnav.responsive {
    position: absolute;
    background: black;
    width: 100%;
}

.topnav.responsive a.icon {
    position: absolute;
    top: -19px;
    right: 0px;
}

.topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
}

.body_landing_wrapper {
    width: 100%;
    height: max-content;
    background: url(assets/images/main_page_bg.png);
    mix-blend-mode: normal;
}


.docLegislation {
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
    color: #666;
    width: 100%;
}

.wrapp_body_landingPage {
    padding: 10px;
    margin: 20px 5%;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.7);
}

.p_landingpage {
    text-align: center;
    font-size: 18px;
}


.landing_main__welcome {
    background: url(assets/images/military_wrapper.png);
    width: 100%;
    height: 835px;
    left: 0px;
    top: 0px;
}

.mycompany {
    font-size: 38px;
    font-weight: 600;
}

.landing_wrapper {
    width: 100%;
    margin: 0 auto;
}

.landing_main__legislation {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
    color: black;
}


.p_contacts {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.first_box_contacts {
    margin-top: 50px;
}

.landing_main__responsibility h1 {
    color: white;
}

.landing_main__howWeWork {
    text-align: center;
    padding-top: 110px;
    padding-bottom: 110px;
    color: #ebd513;
    background: url(/static/media/military_wrapper.3e92000b.png);
}

.landing_main__faq {
    text-align: center;
    padding-top: 110px;
    padding-bottom: 110px;
    color: black;
    height: 300px;
}

.landing_main__contacts {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    color: black;
    background-size: cover;
    height: 200px;
}


.wrapper_header_ {
    background: url(assets/images/wrapper_header.png) 0px no-repeat;
    width: 100%;
    height: 108px;
}

.wrapper_description_1 {
    display: none;
}

.title_publicOffer {
    padding: 20px;
    padding-top: 60px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 20px;
    width: 8%;
}

.docLegislation {
    border-bottom: #b6b5b5 1px solid;
}

.landing_main .landing_main__about {
    display: flex;
    padding: 100px 0 100px 0;
}

.p_second_faq {
    text-indent: 20px;
}

.faq_landing_main .landing_main__about {
    display: flex;
    padding: 60px 0 20px 0;
}

.howWeWork_landing_wrapper .landing_main__about {
    display: flex;
    padding: 60px 0 20px 0;
    flex-direction: column;
    text-align: justify;
}

.publicOffer {
    margin: 50px;
    text-align: justify;
}

.publicOffer .publicOffer-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.publicOffer-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.landingPage-wrappAccordion {
    padding: 10px 20px;
}

.landing_about__right h1 {
    padding-bottom: 10px;
    font-weight: 100;

}

.landing_about__right h1:after {
    display: none;
}

.landing_about_as {
    font-weight: 900;
}

.landing_about__right p {
    margin: 10px 0 30px 0;
    color: #5a5a5a;
}

.landing_about__right li {
    color: #5a5a5a;
    padding-left: 25px;
    margin-top: 10px;
    margin-left: -38px;
}

.landing_about__right li p {
    font-style: italic;
    padding-left: 25px;
    margin-top: 5px;
}

.mw-100 {
    max-width: 150%;
}


.landing_main__team {
    background: black;
    text-align: center;
    padding: 100px 0 165px 0;
    color: white;
}

.landing_main__team h1 {
    font-size: 44px;
    padding-bottom: 1px;
    font-weight: 500;
}

.wrapper_description_cost_left_license div {
    margin-bottom: 22px
}

.landing_body h1:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 100px;
    padding-top: 17px;
}

.landing_photo {
    background: #999999;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 20px auto;
}

.landing_card {
    margin: 0 30px;
}

.landing_team__cards {
    display: flex;
}

.landing_name {
    font-size: 20px;
    margin-bottom: 0px;
}

.landing_position {
    font-size: 18px;
    margin-bottom: 25px;
    margin-top: 5px;
    font-weight: 100;
}

.landing_about__position {
    font-size: 14px;
}


.landing_main__services {
    text-align: center;
    padding: 100px 0 150px 0;
    color: black;
}

.landing_main__services h1 {
    font-size: 44px;
    padding-bottom: 5px;
    font-weight: 800;
}

.landing_main__services p {
    color: #999999;
    font-weight: 100;
    padding-bottom: 70px;
}

.landing_services__cards {
    display: flex;
}

.landing_services__card {
    padding-right: 5px;
    margin-bottom: 30px;
}

.landing_services__card h4 {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.landing_services__card p {
    font-size: 14px;
    color: #5a5a5a;
    margin: 0 25px;
    padding-bottom: 0px;
}


.landing_main__clients {
    background: black;
    padding: 100px 0 205px 0;
    color: white;
    text-align: center;
}

.landing_main__clients h1 {
    font-weight: 100;
    margin-bottom: 35px;
}

.landing_our_clients {
    font-weight: 800;
}

.landing_main__clients img {
    margin-left: 30px;
    margin-top: 10px;
}


.landing_main__testimonials {
    background: black;
    padding: 100px 0 100px 0;
    color: white;
    text-align: center;
}

.landing_main__testimonials h1 {
    font-weight: 100;
    padding-bottom: 10px;
}

.landing_testimonials {
    font-weight: 800;
}

#landing_p1__testimonials {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 25px;
    padding-top: 30px;
}

#landing_p2__testimonials {
    color: #d4d4d4;
    font-weight: 100;
}


.landing_main__work {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 70px;
}

.landing_main__work h1 {
    font-weight: 100;
    padding-bottom: 10px;

}

.landing_our_work {
    font-weight: 600;
}

.landing_main__work p {
    padding-bottom: 80px;
    color: #5a5a5a;
}

.landing_main__work img:hover {
    opacity: 0.9;
}

.landing_main__work img {
    padding-left: 5px;
    padding-bottom: 5px;
}
.title_recv{
    width: 380px;
}
.landing_contact__wrapper {
    width: 100%;
    margin: 0 auto;
}
.wrapper_work_description{
    width:98%;
    margin: 0 auto;
}
.landing_form__inputs {
    display: flex;
    margin-top: 75px;
}


.landing_body textarea {
    height: 163px;
    margin-top: 5px;
    padding: 10px;
}


.landing_button__block {
    display: flex;
    justify-content: flex-end;
}


.landing_contact__us {
    text-align: center;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.landing_contact__us_faq {
    text-align: center;
    background-size: contain;
    height: 120px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.landing_contact__us_faq h1 {
    color: black;
    font-weight: 700;
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 0px;
}

.landing_contact__us_faq h1:after {
    content: "";
    display: block;
    margin: 0 auto;
}

.landing_contact__us_faq p {
    color: black;
    font-weight: 200;
    font-size: 20px;
    padding-bottom: 0px;
}

.landing_contact__us h1 {
    color: black;
    font-weight: 700;
    font-size: 35px;
    padding-top: 40px;
    padding-bottom: 0px;
}


.landing_img__links {
    margin-left: 40%;
}

.landing_img__links img {
    padding-left: 5px;
}

.landing_img__links img:hover {
    opacity: 0.6;
}


.form-control {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 109px;
    box-sizing: border-box;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 58px;
    color: rgba(0, 0, 0, 0.8)
}

.form-control input {
    width: 823px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 33px;
    padding: 0 0 17px 35px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 58px;
    color: rgba(0, 0, 0, 0.8)

}

.form-control input::placeholder, .form-control select {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 58px;
    color: rgba(0, 0, 0, 0.8)
}
.title-calc{
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #EAB40E;
    margin: 79px auto 22px auto;
}
.pre-title-calc{
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    margin-bottom: 22px;
}
.desc-calc{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    margin-bottom: 55px;
}
.wrapper-calculator{
    width: 67%;
    margin: 0 auto;
    margin-bottom: 30px;
}
.title-po{
    font-weight: 500;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
    color: #EAB40E;
    margin-bottom: 20px;
}
.pre-title-po{
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    margin-bottom: 60px;
}
.po-images{
    width: 81%;
    margin: 0 auto;
    justify-content: space-between;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.po-images img{
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.po-images img:first-child {
    height: 49px;
    width: 98px;
}
.po-images img:nth-child(2) {
    height: 53px;
    width: 107px;
}
.po-images img:nth-child(3) {
    height: 59px;
    width: 166px;
}
.po-images img:nth-child(4) {
    height: 95px;
    width: 159px;
}
.po-images img:nth-child(5) {
    height: 42px;
    width: 102px;
}
.wrapper-calculator-form button {
    width: 310px;
    height: 50px;
    background: #C49B14;
    border-radius: 4px;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.wrapper-calculator-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 69px;
}
.calculation-window{
  width: 100%;
    background: #EBFFCD;
height: max-content;
    margin-bottom: 120px;
    border-radius: 4px;
    padding-bottom: 40px;
}
.title-calc-window{
    margin: 0px auto 29px auto;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    padding-top: 40px;
}
.calc-results{
    width: 59%;
    margin: 0 auto 45px auto;
    display: flex;
    flex-direction: column;
}
.calc-results div:nth-child(1),.calc-results div:nth-child(2),.calc-results div:nth-child(3) {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.wrapper-calculator-form select, .wrapper-calculator-form input {
    width: 23%;
    border-radius: 5px;
    background: white;
    border: 1px solid gray;
    height: 50px;
}
.wrapper-calculator-form select, .wrapper-calculator-form input::placeholder{
    padding-left: 15px;
    color: darkgray;
}
.results-calc-title{
    font-style: normal;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 55px;
    text-align: center;

}
.send-form-calc{
    width: 79%;
    display: flex;
   margin:0 auto 42px auto
}
.send-form-calc button{
    height: 50px;
    background: #C49B14;
    border-radius: 0px 4px 4px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 128.69%;
    text-align: center;
    color: #FFFFFF;
    width: 30%;
}
.send-form-calc input::placeholder{
    padding-left: 15px;
}
.send-form-calc input{
    height: 50px;
    width: 70%;
    background: #FFFAFA;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    border-radius: 4px;
}
.form-control select {
    width: 823px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 33px;
    padding: 0 0 17px 35px;
}

.button-send-wrapp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-send {
    width: 300px;
    height: 50px;
    background: #111B06;
    border-radius: 6px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}


.template_landing_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10%;
    background: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 20px;
}

.legislation_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10%;
    padding: 20px;
    border-radius: 20px;
}

.tab-bold {
    font-weight: bold !important;
    color: black;
}

.howWeWork_landing_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3% 6%;
}

.wrapper_header {
    position: absolute;
    width: 68%;
    height: 43px;
    left: 16%;
    top: 36px;
}

.link_offer_pdf {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    top: -26px;
    left: 16%;
}

.link_offer_pdf:hover {
    color: #F0BA14;
}

.title {
    width: 13%;
    position: absolute;
    left: 0%;
    right: 87.92%;
    top: 4.65%;
    bottom: 46.51%;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #E7B41B;
}

.landing_links_mobile {
    display: none;
}

.title_service {
    width: 10%;
    height: 17px;
    position: absolute;
    left: 1.45%;
    right: 89.45%;
    top: 60.47%;
    bottom: 0%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.7);
}

.menu_wrapper {
    width: 53%;
    height: 17px;
    position: absolute;
    left: 17.13%;
    right: 29.89%;
    top: 25.58%;
    bottom: 34.88%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
}

.menu_wrapper_a {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    cursor: pointer;
    font-family: 'Rubik';
    font-size: 14px;
}

.menu_wrapper_activeLink {
    color: rgba(240, 186, 20, 1) !important;
    text-decoration: underline;
    cursor: pointer;
    font-family: Rubik;
}

.menu_wrapper_a:hover {
    text-decoration: underline;
    color: rgba(240, 186, 20, 1) !important;
}

.legislation_list, .legislation_list_1 {
    position: absolute;
    width: 353px;
    /*height: 173px;*/
    left: 436px;
    border-radius: 4px;
    z-index: 2;
    background: linear-gradient(0deg, rgba(9, 19, 1, 0.9), rgba(9, 19, 1, 0.9));
}

.legislation_list {
    top: 36px;
}

.legislation_list_1 {
    top: -154px;
}

.legislation_list::after, .legislation_list_1::after {
    content: '';
    position: absolute;
    left: 8px;
    border: 20px solid transparent;
    border-radius: 4px;
}

.legislation_list::after {
    top: -37px;
    border-bottom: 20px solid rgba(9, 19, 1, 0.9);
}

.legislation_list_1::after {
    top: 132px;
    border-top: 20px solid rgba(9, 19, 1, 0.9);
}

.list_legislation_links {
    margin: 17px 32px 14px 20px;
    width: 301px;
    height: 103px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.wrapper-example-docs{
    background: url(assets/images/example-docs-bg.png);
    height: max-content;
}


.parent-unique-doc{
    width: 502px;
    height: 741px;
}
.title-example{
    font-family: Rubik;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
padding-top: 85px;
    color: #EAB40E;
margin-bottom: 12px;
    text-transform: uppercase;
}
.pre-title-example{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    margin-bottom: 55px;
}


.parent {
    min-width: 161px;
    max-width: 247px;
    margin: 20px;
    height: 226px;
    overflow: hidden;
    position: relative;
    float: left;
    display: inline-block;
    cursor: pointer;
}

.child {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 4px;
}

.parent:hover .child, .parent:focus .child {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.parent:hover .child:before, .parent:focus .child:before {
    display: block;
}

.child:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52,73,94,0.75);
}
.docs-carousel{
    width: 95%;
    margin: 0 auto;
}

.bSWeAW, .hSiCVM{
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}
.bSWeAW:hover:enabled, .bSWeAW:focus:enabled {
    color: #fff;
    background-color: #DBAA14 !important;;
}
.cetQhm{
    box-shadow: 0 0 1px 3px #DBAA14 !important;
    background-color: #DBAA14 !important;
}

.list_legislation_links a {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
}

.list_legislation_links a:hover {
    color: rgba(231, 180, 27, 1) !important;
}

.call img {
    position: absolute;
    right: 24.46%;
    top: 21.27%;
    bottom: 12.5%;
    width: 18px;
    height: 18px;
}

.call a {
    width: 11%;
    position: absolute;
    left: 76.15%;
    right: 13.53%;
    top: 25.58%;
    bottom: 34.88%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #F0BA14;
}

.sign_in_btn {
    position: absolute;
    left: 90.29%;
    right: 0%;
    top: 0%;
    bottom: 9.3%;
    border: 1px solid #F0BA14;
    box-sizing: border-box;
    border-radius: 4px;
    width: 127px;
    height: 39px;
    background: border-box;
    text-decoration: none;
}

.sign_in_btn span {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    left: 30.16%;
    top: 27.91%;
    bottom: 32.56%;
}

.wrapper_description {
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    height: 136px;
    position: absolute;
    width: 60%;
    top: 203px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 124.9%;
    text-align: center;
    mix-blend-mode: normal;
}
.paid ,.paid_pdf{
    cursor: pointer;
}
.wrapper_description span {
    color: #EBD513;

}

.wrapper_description_note {
    display: flex;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
    position: absolute;
    width: 44%;
    height: 68px;
    top: 322px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 142.19%;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 99px;
}

.license_btn {
    text-decoration: none;
    position: absolute;
    width: 339px;
    height: 62px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 503px;
    background: #DBAA14;
    border-radius: 4px;

}
.wrapLicenceBtns{
    position: absolute;
    color: white;
    top: 551px;
    width: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.wrapLicenceBtns div span{
    margin-bottom:15px;
    height: 60px;
    width: 481px;
    border-radius: 4px;
    background: #DBAA14;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
}

.wrap-description-licence{
    width: 650px;
    height: 131px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 200%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.wrap-description-licence a:hover, .wrapLicenceBtns-child a:hover {
    color:#DBAA14 !important;
}
.know-match{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-left: 44px;
}
.know-match_{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-left: 29px;
    display:block;
    margin-top:60px;
}
.licence-items{
    display: flex;
    align-items: center;
    margin:0px;
}
.licence-items img {
    margin-right: 10px;
}
.license_btn div {
    position: absolute;
    width: 339px;
    height: 17.4px;
    top: 27%;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 128.69%;
    text-align: center;
    color: #FFFFFF;
}

.list_description {
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
    position: absolute;
    width: 68%;
    height: 46.66px;
    top: 708.64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list_description img {
    width: 33px;
    height: 33px;
    margin: 0.4% 5px 0 0;
}

.list_point, .list_point_1, .list_point_2, .list_point_3 {
    height: 47px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    display: inline-block;
}

.list_point {
    width: 236px;
}

.list_point_1 {
    width: 263px;
}

.list_point_2 {
    width: 293px;
}

.list_point_3 {
    width: 262px;
}

.wrapper_military_registration {
    width: 77%;
    margin: 0px auto 0px auto;
}
.wrapper_military_registration div{
    margin-bottom: 20px;
}
.what_is_mr {
    width: 411px;
    height: 84px;
    display: block;
    position: relative;
    top: 90px;
    margin: 0 auto 80px auto;
}

.title_law_military_registration {
    width: 1132px;
    height: 31px;
    margin: 55px auto 22px auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 154%;
    color: #000000;
    text-align: center;
}
.wrapper-why-us-comp-item span {
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

}

.list_mr li {
    list-style-type: none;
    width: 1147px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 154%;
    color: #000000;
}

.list_mr li:first-child {
    height: 78px;
}

.list_mr li:nth-child(2n) {
    height: 28px;
    margin-bottom: 16px;
}

.list_mr li:nth-child(3) {
    height: 28px;
    margin-bottom: 16px;
}

.list_mr li:nth-child(5) {
    height: 28px;
    margin-bottom: -7px;
}

.list_mr img {
    width: 21px;
    height: 25px;
    margin-right: 21px;
}

.list_mr {
    margin-bottom: 32px;
}

.footer_military_registration {
    margin: 0 auto;
    width: 1163px;
    height: 28px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 154%;
    color: #000000;
}

.wrapper_our_work {
    border: 1px solid transparent;
    margin: 0px auto 120px auto;
    width: 1116px;
    height: 605px;
}

.our_work_mr_img {
    margin: 0 auto 92px auto;
    width: 437px;
    height: 84px;
    display: block;
}

.title_our_work_mr {
    margin: -47px auto 8px auto;
    width: 537px;
    height: 31px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 154%;
    color: #000000;
}

.vectors {
    width: 623px;
    margin: 0 auto 8px auto;
    display: flex;
    justify-content: space-between;
}

.left_vector, .right_vector {
    width: 107px;
    height: 41px;
}

.title_mode {
    margin: 0 auto 35px auto;
    width: 865px;
    height: 28px;
    display: flex;
    justify-content: space-between;
}

.title_mode_par {
    margin: 0 auto 35px auto;
    height: 28px;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: flex-end;
}


.title_mode_point_1 img, .title_mode_point_2 img {
    width: 18px;
    height: 26px;
    margin-right: 19px;
}

.title_mode_point_1 div, .title_mode_point_2 div {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 154%;
    color: #000000;
}
.title_mode_point_1 div:first-child, .title_mode_point_2 div:first-child{
    text-align: center;
}

.title_mode_point_1, .title_mode_point_2 {
    display: flex;
    line-height: 28px;
}

.title_mode_point_1 {
    margin-left: 31px;
}

.title_mode_point_2 {
    margin-right: 79px;
}

.mode_list_left li, .mode_list_right li {
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 154%;
    color: #000000;
    width: 445px;
    height: 50px;
    margin-bottom: 19px;
}

.mode_list_left, .mode_list_right {
    padding: 0px;
}

.mode_list_left li:first-child {
    margin-bottom: 26px;
}
/*.dialog-po{*/
/*    width: 50%;*/
/*    height: 422px;*/
/*}*/
.title-dialog-po{
    font-weight: normal;
    font-size: 20px;
    line-height: 151.5%;
    text-align: center;
    color: #000000;
    width: 91%;
    margin: 74px auto 50px auto !important;
}
.title-dialog-po img {
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;
}
.title-gratitude{
    font-weight: 500;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}
.pre-title-gratitude{
    font-size: 20px;
    line-height: 151.5%;
    text-align: center;
    color: #000000;
}
.content-dialog-po div {
    width: 91%;
    margin: 0px auto 60px auto !important;
}
.content-dialog-po input {
    width: 100%;
    height: 50px;
    border: 1px solid darkgray;
    margin-bottom: 60px;
}
.content-dialog-po button {
    width: 100%;
    height: 50px;
    background: #C49B14;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
}
.mode_list_right li:first-child {
    margin-bottom: 22px;
    height: 25px;
}

.mode_list_left li:nth-child(2) {
    margin-bottom: 29px;
}

.mode_list_right li:nth-child(2) {
    margin-bottom: 55px;
    height: 53px;
}

.mode_list_left li:nth-child(3), .mode_list_right li:nth-child(3) {
    width: 465px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 154%;
}

.mode_list_left a, .mode_list_right a {
    padding-right: 10px;
    color: black;
    text-decoration-line: underline !important;
}

.img_note {
    width: 13px;
    height: 16px;
    position: relative;
    top: 4px;
}

.mode_list {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.mode_list_par {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.mode_list li {
    list-style-type: none;
}

.wrapper_cost {
    width: 100%;
    height: 544px;
    margin: 0 auto;
    background: linear-gradient(0deg, rgba(9, 19, 1, 0.4), rgba(9, 19, 1, 0.4)), url(assets/images/military_wrapper_2.png)
}

.wrapper_cost_mg {
    width: 100%;
    height: 544px;
    margin: 1685px auto 0 auto;
    background: linear-gradient(0deg, rgba(9, 19, 1, 0.4), rgba(9, 19, 1, 0.4)), url(assets/images/military_wrapper_2.png)
}

.wrapper_cost_mg_1 {
    width: 100%;
    height: 544px;
    margin: 1595px auto 0 auto;
    background: linear-gradient(0deg, rgba(9, 19, 1, 0.4), rgba(9, 19, 1, 0.4)), url(assets/images/military_wrapper_2.png)
}

.title_cost {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #F0BA14;
    margin-left: 81px;
    margin-right: 80px;
}

.cost_img {
    width: 39px;
    height: 39px;
}

.wrapper_title_cost {
    position: relative;
    top: 57px;
    margin-bottom: 67px;
    display: flex;
    justify-content: center;
    margin-bottom: 67px;
}

.wrapper_description_cost {
    width: 70%;
    margin: 0 auto;
    position: relative;
    top: 50px;
    display: flex;
    justify-content: space-between;
}

.wrapper_description_cost_left, .wrapper_description_cost_right {
    width: 600px;
    height: 102px;
    display: flex;
}

.wrapper_description_cost_left_title_license {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 28px;
    margin-right: 44px;
}
.wrapper_description_cost_left_license div:first-child{
    margin-bottom: 10px;
}

.wrapper_description_cost_left_license div {
    margin: 0 auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 115%;
    color: rgba(255, 255, 255, 0.95);
}

.possibility_list {
    display: flex;
}

.wrapper_description_cost_left_license {
    height: 54px;
}

.cost_footer {
    width: 70%;
    height: 93px;
    position: relative;
    top: 40px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cost_footer div {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 144%;
    text-align: center;
    color: #FFFFFF;
}

.landing_wrapper_footer {
    width: 100%;
    height: 861px;
    background: url(assets/images/footer_bg.png);
    mix-blend-mode: normal;
}

.landing_questions {
    position: relative;
    top: 90px;
    margin: 0 auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #EAB40E;
}

.landing_questions_connect {
    position: relative;
    top: 116px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 154%;
    color: #000000;
    text-align: center;
    margin: 0 auto;
}

.landing_wrapper_contacts {
    width: 1254px;
    position: relative;
    top: 185px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
}

.form_control_landing input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.9);
}

.form_control_landing input {
    border: 1px solid rgba(0, 0, 0, 0.9);
    box-sizing: border-box;
    border-radius: 8px;
    height: 50px;
    width: 533px;
    border-radius: 8px;
    font-family: Rubik;
    padding: 16px 0 12px 28px;
    margin-bottom: 18px;
}

.form_control_landing textarea {
    width: 533px;
    height: 147px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.9);
    padding: 19px 0 0 24px;
    border-radius: 8px;
    margin-bottom: 60px;
    border: 1px solid rgba(0, 0, 0, 0.9);
}


.btn_call {
    margin-right: 73px;
    width: 533px;
    height: 55px;
    background: #111B06;
    border-radius: 8px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: #FFFFFF;
}

.btn_call:hover{
    cursor: pointer;
}


button:hover{
    cursor: pointer;
}

.wrapper_addresses {
    width: 648px;
    height: 405px;
    background: linear-gradient(0deg, rgba(9, 19, 1, 0.7), rgba(9, 19, 1, 0.7)), url(assets/images/military_wrapper_3.png);
    border-radius: 6px;
}

.top_block_addresses {
    height: 99px;
    width: 100%;
    margin: 14px auto 17px auto;
}

.wrapper_work_description_mobile {
    display: none;
}
.wrap-carousel{
    width: 52%;
    height: 170px;
    background: white;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 711px;
    margin-top: 30px;
}
.wrapper-why-us-comp{
    display: flex;
    justify-content: center;
}
.wrapper-why-us-comp-item{
    width: 46%;
}
.top_block_addresses_title {
    font-family: Rubik;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.top_block_addresses_title_2 span {
    display: inline-block;
    font-family: Rubik;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.top_block_addresses_title_2 {
    text-align: center;
    margin:0 auto;
}

.top_block_addresses_title_3 {
    display: flex;
    height: 48px;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    width: 90%;
    margin: 16px auto 0px auto;
}

.down_block_addresses_title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Rubik;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    height: 204px;
    width: 555px;
   margin-left: 20px;
}

.landing_footer, .landing_footer_legislation, .landing_footer_basicProvisions,
.landing_footer_responsibility, .landing_footer_our_contacts {
    position: absolute;
    left: 0;
    height: 203px;
    width: 100%;
    background: url(assets/images/footer.png);
}

.landing_footer_faq {
    position: absolute;
    left: 0;
    height: 205px;
    width: 100%;
    background: url(assets/images/footer.png);
    top: 198px;
}

.landing_footer_offer {
    height: 203px;
    width: 100%;
    background: url(assets/images/footer.png);
}

.landing_footer_login {
    position: absolute;
    left: 0;
    height: 203px;
    width: 100%;
    background: url(assets/images/footer.png);
    top: 90px;
}

.footer_license_mobile {
    display: none;
}

.landing_links {
    width: 56%;
    height: 17px;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -12px;
    left: 28%;
}

.landing_links_links {
    text-decoration: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}

.activeLink {
    color: rgba(240, 186, 20, 1) !important;
    text-decoration: underline;
}

.activeLink_low {
    text-decoration: none;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    color: rgba(240, 186, 20, 1) !important;
    text-decoration: underline;
}
.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    height: max-content;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3a3a3a;
    overflow: scroll;
}
.image-viewer{
    width: 90%;
}
.viewer-close-btn{
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    background: none;
    font-weight: bold;
    font-size: 26px;
    color: #999999;
}
.footer_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F0BA14;
    position: relative;
    top: 42px;
    margin-left: 16%;
    margin-bottom: 35px;
    display: block;
}

.footer_line {
    margin-left: 16%;
    margin-bottom: 21px;
    margin-top: 35px;
    width: 68%;
}

.footer_license {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 68%;
    margin-left: 16%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.footer_btn:hover {
    color: #DBAA14
}

.footer_btn {
    cursor: pointer;
    border: 1px solid #F0BA14;
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 178px;
    left: 1435px;
    top: 32px;
    border-radius: 4px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: border-box;
}

.details_block {
    width: 100%;
    height: max-content;
    position: absolute;
    left: 0px;
    margin-top: 39px;
}

.details_block_1 {
    width: 100%;
    height: max-content;
    position: absolute;
    left: 0px;
    margin-top: 39px;
}

.details_block_text, .details_block_text_more {
    position: absolute;
    width: 64%;
    height: 1482px;
    left: 311px;
    top: 2174px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 19px;
}

.details_block_text div, .details_block_text_more div {
    margin-bottom: 15px;
}

.details_block_text a, .details_block_text_more a {
    text-decoration: none;
}

.registration_ {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 58px;
    text-align: center;
    text-transform: uppercase;
    color: #EAB40E;
    position: relative;
    top: 126px;
}

.wrapper_titles {
    width: 100%;
    height: 784px;
    background: url(assets/images/registration_bg.png);
}

.titles_form {
    width: 1040px;
    height: 112px;
    position: relative;
    top: 149px;
    margin: 0 auto 56px auto;
}

.titles_form div {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #000000;
}

.wrapper_contacts {
    margin-top: 650px;
    margin-bottom: 335px;
}

.wrapper_offer_page {
    width: 99%;
    height: max-content;
    background: url(assets/images/wrapper_off.png);
    mix-blend-mode: normal;
    margin-bottom: 70px;
}

.title_offer {
    margin: 50px auto 25px auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 58px;
    text-align: center;
    text-transform: uppercase;
    color: #EAB40E;
    height: 175px;
    width: 1109px;
    left: 99px;
}

.title_offer_1 {
    width: 829px;
    height: 23px;
    margin: 0 auto 25px auto;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
}
.mode_list_right li:last-child{
    padding-right: 10px;
}
.body_offer {
    margin: 0 auto;
    width: 1308px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
}

.offer_date {
    display: flex;
    justify-content: space-between;
}

.wrapper_provisions {
    height: 784px;
    width: 100%;
    background: url(assets/images/wrapper_provisions.png);
}

.provisions_pre_title, .provisions_pre_title_1, .provisions_pre_title_2, .provisions_pre_title_3, .provisions_pre_title_4 {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 154%;
    height: 22px;
    position: relative;
    top: 40px;
    display: flex;
    justify-content: space-between;
    left: 308px;
}

.wrap_work {
    margin-top: 40px;
}

.provisions_pre_title_4 {
    width: 254px;
}

.provisions_pre_title_1 {
    width: 441px;
}

.provisions_pre_title {
    width: 629px;
}

.provisions_pre_title_2 {
    width: 694px;
}

.provisions_pre_title_3 {
    width: 154px;
}

.provisions_title, .provisions_title_1, .provisions_title_2 {
    position: relative;
    margin: 0 auto;
    top: 87px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 58px;
    text-align: center;
    text-transform: uppercase;
    color: #EAB40E;
}

.provisions_title {
    height: 168px;
    width: 1294px;
}

.provisions_title_1 {
    height: 117px;
    width: 1032px;
}

.provisions_title_2 {
    height: 59px;
    width: 813px;
}

.provisions_body, .provisions_body_1, .provisions_body_2 {
    height: max-content;
    position: relative;
    top: 86px;
    margin: 0 auto;
    font-family: Rubik;
    font-style: normal;
}

.provisions_body {
    width: 1300px;
    padding-bottom: 151px;
}

.provisions_body_1 {
    margin-top: 60px;
}

.provisions_body_2 {
    margin-top: 58px;
    width: 1306px;
    background: linear-gradient(1.9deg, #FFFFFF 7.2%, rgba(255, 255, 255, 0) 40.05%);
    margin-bottom: 117px;
}

.wrapper_responsibility {
    width: 100%;
    height: 784px;
    background: url(assets/images/wrapper_responsibility.png);
}

.wrapper_main_legislation {
    width: 100%;
    padding-bottom: 72px;
    background: url(assets/images/wrapper_legislation.png);
}

.footer_legislation {
    position: relative;
    top: 85px;
}

.wrapper_faq {
    background: url(assets/images/wrapper_faq.png);
    width: 100%;
    /*height: 784px;*/
}


.mask {
    position: absolute;
    transform: rotate(-0.05deg);
    top: -40px;
    left: 640px;

}

.footer_mobile, .wrap_work_mobile, .what_is_mr_mobile, .wrapper_description_mobile {
    display: none;
}

.titleWays {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin-bottom: 25px;
}
.lineWays{
    border: 1px solid rgba(0, 0, 0, 0.4);
    width: 100%;
    margin-bottom: 35px;
}
.paid_logos{
    background: rgba(255, 255, 255, 0.6);
    mix-blend-mode: normal;
    border-radius: 6px;
    height: 66px;
    width: 50%;
}
.text_way_paid ul li{
    list-style-type: none;
    padding-bottom: 20px;
}
.text_way_paid ul {
    padding-left: 15px;
}
.erip_text, .webPay_text{
    display: flex;
    justify-content: space-between;
}
.text_way_paid{
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.listEripStyle li{ 
    padding-bottom: 0px !important;
}
.contentModal{
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: 1168px) {
    .contentModal{
        width: 230%;
    }
}
@media (max-width: 1168px) and (min-width: 800px) {
    .contentModal{
        width: 156%;
    }
}
@media (max-width: 800px)  {
    .contentModal{
        width: 100%;
    }
    .webPay_text, .erip_text{
        flex-direction: column;
    }
}
@media (min-width: 1600px) and (max-width: 1802px) {
    .wrapper_header {
        left: 50%;
        transform: translate(-50%, -50%);
        width: 77%;
        z-index: 2;
    }

    .list_description {
        width: 78%;
    }
}

@media (min-width: 1400px) and (max-width: 1600px) {
    .menu_wrapper {
        width: 55%;
        left: 16.13%;
    }

    .menu_wrapper_a, .call a, .menu_wrapper_activeLink {
        font-size: 12px;
    }

    .title {
        width: 17%;
        font-size: 15px;
    }

    .title_service {
        width: 13%;
        font-weight: normal;
        font-size: 13px;
    }

    .list_description {
        width: 87%
    }

    .landing_links_links, .activeLink_low {
        font-size: 11px;
    }

    .footer_title {
        font-size: 17px;
    }

    .footer_btn {
        height: 40px;
        width: 154px;
        font-size: 12px;
    }

    .wrapper_header {
        width: 75%;
    }
}

@media (min-width: 999px) and (max-width: 1400px) {
    .provisions_body_2 {
        width: 99%;
    }

    .btn_call {
        width: 431px;
    }

    .wrapper_header {
        width: 79%;
    }

    .menu_wrapper {
        width: 57%;
        left: 16.13%;
    }

    .menu_wrapper_a, .call a, .menu_wrapper_activeLink {
        font-size: 10px;
    }

    .call img {
        width: 16px;
        height: 16px;
    }

    .sign_in_btn {
        width: 110px;
        height: 38px;
    }

    .wrapper_description {
        font-size: 41px;
    }

    .wrapper_description_note {
        font-size: 20px;
    }

    .call a {
        width: 13%;
        left: 77.15%;
    }

    .call img {
        right: 23.46%;
        top: 22.27%;
    }

    .sign_in_btn span {
        font-size: 12px;
    }

    .title {
        width: 18%;
        font-size: 13px;
    }

    .title_service {
        width: 14%;
        font-weight: normal;
        font-size: 10px;
    }

    .list_description {
        width: 87%;
        margin: 0 50px;
        top: 676px;
    }

    .possibility_list {
        padding-bottom: 20px;
    }

    .landing_links_links, .activeLink_low {
        font-size: 10px;
    }

    .footer_title {
        font-size: 14px;
    }

    .footer_btn {
        height: 37px;
        width: 95px;
        font-size: 10px;
    }

    .landing_links {
        width: 68%;
        left: 29%;
    }

    .footer_license {
        font-size: 12px;
    }

    .footer_line {
        width: 81%;

    }

    .title_law_military_registration, .list_mr, .list_mr li, .wrapper_military_registration {
        width: 100%;
    }

    .title_law_military_registration {
        text-align: center;
        margin-bottom: 42px;
    }

    .footer_military_registration {
        text-align: center;
        width: 90%;
    }

    .title_our_work_mr {
        width: 100%;
        text-align: center;
        
    }

    .landing_wrapper_contacts {
        width: 98%;
    }

    .form_control_landing input, .form_control_landing textarea, .btn_cal {
        width: 431px;
    }

    .down_block_addresses_title {
        width: 397px;
        font-size: 14px;
        top: 12px;
        right: 2px;
    }

    .wrapper_our_work {
        width: 100%;
    }
}

@media (min-width: 999px) and (max-width: 1184px) {
    .mask {
        height: 96px;
        position: absolute;
        transform: rotate(-0.05deg);
        top: -31px;
        left: 558px;
        width: 73px;
    }
}


@media (min-width: 364px) and (max-width: 699px) {
    .list_mr li:nth-child(3) {
        margin-bottom: 15px;
    }
}

@media (min-width: 366px) and (max-width: 699px) {
    .list_mr li:nth-child(5) {
        margin-bottom: -1px;
    }
}

@media (min-width: 449px) and (max-width: 645px) {
    .list_mr li:first-child {
        margin-bottom: 1px;
    }
}

@media (min-width: 486px) and (max-width: 699px) {
    .list_mr li:nth-child(4) {
        margin-bottom: 15px;
    }
}

@media (min-width: 645px) and (max-width: 764px) {
    .list_mr li:first-child {
        margin-bottom: -18px;
    }
}

@media (min-width: 700px) and (max-width: 764px) {
    .list_mr li:first-child {
        margin-bottom: 17px;
    }
}
@media (max-width: 700px) {
    .what_is_mr_mobile span {
        font-family: Rubik;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: center;
        color: #DBAA14;
    }
}
/*!!!!!!!!!!!!!!!!!!!!361-450px!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
@media (min-width: 361px) and (max-width: 450px) {
    .landing_main__welcome{
        width:100%;
    }
    .title-example{
        font-size:32px;
    }
    .wrapper_description_cost {
        width: 100%;
        flex-direction: column;
    }
    .wrapLicenceBtns-child{
        width: 100%;
    }
    .wrap-description-licence{
        padding-left: 20px;
    }
    .wrapLicenceBtns-child_two{
        width:100% !important;
    }
    .go-to-registration-organization_btnc{
        width: 300px;
        font-size: 14px;
    }
    .landing_questions_connect{
        font-size: 15px;
    }
    .landing_questions{
        font-size: 34px;
    }

    .wrapper_description_cost_left {
        margin: 10px auto 0px auto;
    }

    .wrapper_description_cost_right {
        margin: 0px auto;
    }

    .wrapper_description_cost_left_title_license {
        margin:5px auto 20px;
    }
    .wrapper_cost{
        height: 635px;
    }

    .cost_footer {
        width: 100%;
        top: 72px;
    }

    .menu_wrapper_mobile, .burger-menu-mobile {
        display: block;
    }

    .menu_wrapper, .call, .vectors, .wrapper_work_description {
        display: none;
    }

    .wrapper_header {
        width: 95%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .menu_wrapper {
        width: 57%;
        left: 16.13%;
    }

    .menu_wrapper_a, .call a {
        font-size: 10px;
    }

    .call img {
        width: 16px;
        height: 16px;
    }

    .sign_in_btn {
        display: none;
    }

    .wrapper_description {
        font-size: 27px;
        width: 95%;
        
    }

    .wrapper_description_note {
        font-size: 15px;
        width: 73%;
        

    }
    .wrapLicenceBtns{
        margin:auto;
        
    }
    .body_landing_wrapper{
        widows: 100%;;
    }

    .possibility_list {
        width: 326px;
        margin-bottom: 15px;
    }

    .call a {
        width: 13%;
        left: 77.15%;
    }

    .call img {
        right: 23.46%;
        top: 22.27%;
    }

    .sign_in_btn span {
        font-size: 12px;
    }

    .title {
        width: 34%;
        font-size: 13px;
    }

    .login {
        position: absolute;
        top: 6px;
        height: 33px;
        right: 94px;
    }

    .title_service {
        width: 28%;
        font-weight: normal;
        font-size: 11px;
    }

    .list_description {
        width: 90%;
        justify-content: center;
        top: 606px;
    }

    .list_point, .list_point_1, .list_point_2, .list_point_3 {
        font-size: 13px;
    }

    .list_description img {
        width: 26px;
    }

    .title_mode_point_1, .title_mode_point_2 {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
    }

    .mode_list_left, .mode_list_right {
        list-style-type: none;
        margin-left: 20px;
    }

    .what_is_mr {
        top: 50px;
    }

    .title_mode_point_1_title, .title_mode_point_2_title {
        display: flex;
        justify-content: center;
    }

    .footer_title {
        font-size: 14px;
        margin: 20px;
        display: block;
        position: static;
        text-align: center;
    }

    .landing_links_links, .activeLink_low {
        font-size: 10px;
        padding-right: 27px;
        width: 137px;
    }

    .footer_btn {
        display: none;
    }

    .landing_links {
        width: 322px;
        height: 92px;
        position: static;
        width: 322px;
        height: 92px;
        flex-wrap: wrap;
        justify-content: center;
        margin:0px auto 20px;
    }

    .footer_license {
        display: none;
    }

    .footer_license_mobile {
        display: block;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-align: center;
        margin:20px;
    }

    .footer_line {
        width: 340px;
        display: block;
        margin: -16px auto 7px auto;
    }

    .title_law_military_registration, .list_mr, .list_mr li, .wrapper_military_registration, .footer_military_registration {
        width: 100%;
    }

    .title_law_military_registration {
        text-align: center;
        margin-bottom: 76px;
        margin-top: 0px;
        font-size: 17px;
    }

    .wrap_work {
        margin-top: 78px;
    }

    .mode_list_left li:nth-child(2) {
        margin-bottom: 18px;
    }

    .mode_list_right li:nth-child(2) {
        margin-bottom: 16px;
    }

    .mode_list_right li:nth-child(3) {
        width: 334px;
    }

    .img_note {
        top: 0px;
    }

    .mode_list_left li, .mode_list_right li {
        width: 100%;
        font-size: 14px;
    }

    .mode_list_left li:nth-child(3) {
        width: 325px;
        font-size: 16px;
    }

    .form_control_landing {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 63px;
    }
    .landing_footer{
        height: auto;
        width: 100%;
    }

    .wrapper_addresses {
        margin: 0 auto 3px auto;
        width: 100%;
    }
    .wrapper_cost_pdf{
        
        flex-direction: column;
        margin: 65px auto 0px auto !important;
       
        align-items: center;
    }
    .wrapper_cost_pdf button{
        margin:3px;
    }

    .top_block_addresses_contacts {
        display: block;
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        color: #EAB40E;
    }

    .top_block_addresses_title_2 span {
        font-size: 13px;
    }

    .top_block_addresses_title {
        font-size: 14px;
    }

    .footer_military_registration {
        text-align: center;
        font-size: 16px;
    }

    .landing_wrapper_contacts {
        width: 100%;
        flex-direction: column;
    }

    .mask {
        display: none;
    }

    .btn_call {
        margin-right: 0px;
        width: 100%;
    }

    .wrapper_title_cost {
        top: 8px;
        margin-bottom: -31px;
    }

    .title_cost {
        margin-left: 20px;
        margin-right: 20px;
    }

    .form_control_landing div {
        width: 95%;
    }

    .form_control_landing input, .form_control_landing textarea {
        width: 100%;
    }

    .top_block_addresses {
        margin: 10px auto 17px auto;
        width: 90%;
        text-align: left;
    }

    .top_block_addresses_title_2 {
        text-align: center;
    }

    .wrapper_our_work {
        width: 100%;
    }

    .title_mode {
        width: 100%;
    }

    .wrapper_work_description_mobile {
        display: block;
    }

    .footer_desktop {
        display: none;
    }

    .footer_mobile {
        display: block;
    }

    .title_our_work_mr {
        width: 100%;
        text-align: center;
        line-height: 97%;
        margin-bottom: 18px;
        margin-top: -75px;
        font-size: 19px;
    }
    .wrap_work, .what_is_mr {
        display: none;
    }

    .wrap_work_mobile {
        display: flex;
        margin-top: -84px;
    }

    .what_is_mr_mobile {
        width: 100%;
    display: flex;
        justify-content: center;
        margin-top: 58px;
    }

    .our_work_mr_img {
        margin: 82px auto 70px auto;
        width: 300px;
        height: 81px;
        display: block;
    }

    .list_mr, .list_mr_1 {
        padding-left: 5px;
        display: flex;
    }

    .list_mr {
        flex-direction: column;
    }

    .list_mr_1 {
        flex-direction: row;
        align-items: center;
    }

    .list_mr img {
        width: 17px;
        margin-right: 11px;
    }

    .list_mr li, .list_mr_1 li {
        font-size: 15px;
        line-height: 135%;
    }

    .list_mr li:first-child {
        margin-bottom: 21px;
    }

    .list_mr li:nth-child(2) {
        margin-bottom: 14px;
    }

    .list_mr li:nth-child(3) {
        margin-bottom: 29px;
    }

    .list_mr li:nth-child(4) {
        margin-bottom: 30px;
    }

    .list_mr li:nth-child(5) {
        margin-bottom: 19px;
    }
}
/*!!!!!!!!!!!!!!!!!! 451 - 750px!!!!!!!!!!!*/
@media (min-width: 450px) and (max-width: 1200px) {
    .landing_main__welcome{
        width: 100%;
        height: 1200px !important;
    }
    .wrap-description-licence{
        margin:auto !important;
        padding-left: 25px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    }
    .licence-items-two{
        margin:0px;
    }
    .wrapLicenceBtns{
        top:775px !important;
    }
    .wrapLicenceBtns-child_two{ 
        width: 100% !important;
    }
    .go-to-registration-organization_btnc{
        width:80%;
        margin:20px auto;
    }
    .wrapper_description_cost {
        width: 98%;
    } 
    .wrapLicenceBtns-child{
        width: 100%;
    }

    .cost_footer {
        width: 79%;
    }

    .menu_wrapper_mobile, .burger-menu-mobile {
        display: block;
    }

    .menu_wrapper, .call, .vectors, .wrapper_work_description {
        display: none;
    }

    .wrapper_header {
        width: 95%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .menu_wrapper {
        width: 57%;
        left: 16.13%;
    }

    .menu_wrapper_a, .call a {
        font-size: 10px;
    }

    .call img {
        width: 16px;
        height: 16px;
    }

    .sign_in_btn {
        display: none;
    }

    .wrapper_description {
        font-size: 26px;
        width: 83%;
    }

    .wrapper_description_note {
        font-size: 14px;
        width: 51%;
        top:290px !important;
    }

    .possibility_list {
        width: 326px;
        margin-bottom: 15px;
    }

    .call a {
        width: 13%;
        left: 77.15%;
    }

    .call img {
        right: 23.46%;
        top: 22.27%;
    }

    .sign_in_btn span {
        font-size: 12px;
    }

    .title {
        width: 34%;
        font-size: 13px;
    }

    .login {
        position: absolute;
        top: 6px;
        height: 33px;
        right: 94px;
    }

    .title_service {
        width: 35%;
        font-weight: normal;
        font-size: 11px;
    }

    .list_description {
        width: 86%;
        justify-content: center;
        top: 610px;
    }
    .form_control_landing input, .form_control_landing textarea{
        width: 100%;
    }
    .form_control_landing div{
        width: 95%;
    }
    .title_mode_point_1, .title_mode_point_2 {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
    }

    .mode_list_left, .mode_list_right {
        list-style-type: none;
        margin-left: 20px !important;
        width: 97%;
    }

    .what_is_mr {
        top: 50px;
    }

    .title_mode_point_1_title, .title_mode_point_2_title {
        display: flex;
        justify-content: center;
    }

    .footer_title {
        font-size: 14px;
        left: -53px;
        top: 13px;
    }

    .landing_links_links, .activeLink_low {
        width: 142px;
        font-size: 10px;
        margin-bottom: 10px;
    }

    .footer_btn {
        height: 37px;
        width: 95px;
        font-size: 10px;
        left: 468px;
        position: static;
        top: -53px;
    }

    .legislation_list_1 {
        background: linear-gradient(0deg, rgb(9 19 1), rgb(9 19 1));
        left: -13px;
        top: -128px;
    }

    .landing_links {
        width: 375px;
        height: 51px;
        top: -6px;
        left: 67px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: start;
    }

    .footer_license {
        font-size: 12px;
        position: relative;
        left: -35px;
        top: 24px;
        width: 81%;
    }

    .footer_line {
        width: 81%;
        margin: 0 auto;
        display: block;
    }

    .title_law_military_registration, .list_mr, .list_mr li, .wrapper_military_registration, .footer_military_registration {
        width: 100%;
    }

    .title_law_military_registration {
        text-align: center;
        margin-bottom: 42px;
        margin-top: 0px;
    }

    .wrap_work {
        margin-top: -15px;
    }

    .mode_list_left li:nth-child(2) {
        margin-bottom: 18px;
    }

    .mode_list_right li:nth-child(2) {
        margin-bottom: 16px;
    }

    .mode_list_right li:nth-child(3) {
        width: 482px;
    }

    .img_note {
        top: 0px;
    }

    .form_control_landing {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 63px;
    }

    .wrapper_addresses {
        margin: 0 auto 2px auto;
        width: 100%;
    }
    .landing_questions_connect{
        font-size:16px;
    }

    .top_block_addresses_contacts {
        display: block;
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        color: #EAB40E;
    }

    .footer_military_registration {
        text-align: center;
    }

    .title_our_work_mr {
        width: 100%;
        text-align: center;
        margin:-47px auto 30px auto;
    }
    .wrapper_cost_pdf {
        justify-content:space-evenly !important;
        width: 100% !important;

    }

    .landing_wrapper_contacts {
        width: 100%;
        flex-direction: column;
    }

    .mask {
        display: none;
    }

    .btn_call {
        margin-right: 0px;
        width: 100%;
    }
    .mode_list_left li, .mode_list_right li{
        width: 100%;
    }

    .down_block_addresses_title {
        width: 96% !important;
        font-size: 14px;
        top: 12px;
    }
    .mode_list_left li:nth-child(2){
        width: auto
    }
    .mode_list_right li:first-child{
        width: auto;
    }

    .top_block_addresses {
        margin: 10px auto 17px auto;
        
        text-align: left;
    }

    .top_block_addresses_title_2 {
        text-align: center;
    }

    .wrapper_our_work {
        width: 100%;
    }

    .title_mode {
        width: 100%;
    }
    .wrapper_description_cost_left_license{
    margin: 0px auto 20px;
    }
    
    .wrapper_work_description_mobile {
        display: block;
    }

    .footer_desktop {
        display: none;
    }

    .footer_mobile {
        display: block;
    }

}
@media (max-width: 1580px) and (min-width: 1400px) {
    .wrapper-calculator{
        width: 76%;
    }
}
@media (max-width: 1400px) and (min-width: 1200px) {
    .wrapper-calculator{
        width: 88%;
    }
}
@media (max-width: 1200px) and (min-width: 1000px) {
    .wrapper-calculator{
        width: 97%;
    }
    .wrapper-calculator-form button{
        width: 264px;
    }
}
@media (min-width: 361px) and (max-width: 523px) {
    .legislation_list_1::after {
        top: 155px;
    }

    .legislation_list_1 {
        top: -145px;
        left: 121px;
        width: 187px;
        display: flex;
        flex-wrap: wrap;
        height: 157px;
    }

    .list_legislation_links {
        margin: 17px 23px 14px 20px;
        width: 284px;
    }

    .list_legislation_links a {
        font-size: 9px;
        line-height: 18px;
        padding-bottom: 10px;
        z-index: 2;
    }
}

@media (min-width: 523px) and (max-width: 700px) {
    .legislation_list_1 {
        background: linear-gradient(0deg, rgb(9 19 1), rgb(9 19 1));
        left: 121px;
        top: -128px;
    }
}

@media (min-width: 350px) and (max-width: 361px) {
    .landing_footer_legislation, .landing_footer_basicProvisions, .landing_footer_responsibility,
    .landing_footer_offer, .landing_footer_login {
        padding-left: 10px;
    }
}
@media  (min-width: 525px) {
    .mode_list_left li:nth-child(3), .mode_list_right li:nth-child(3) {
        width: 100%;
    }
}
 /*!!!!320-360//*/ 
 @media (min-width: 320px) and (max-width: 361px){
    .wrapper_cost_pdf {
        
       flex-direction: column;
       padding: 12%;
    }
    .wrapper_cost_pdf button{
        margin:5px;
    }
    .img_note {
        position: relative;
        top: 1px;
    }
    .bm-burger-button {
        top: 23px !important;
        right: 20px;
    }

    .faq {
        width: 100%;
    }

    .landing_footer_basicProvisions, .landing_footer_responsibility, .landing_footer_offer {
        padding-left: 10px;
    }

    .legislation_list_1::after {
        top: 155px;
    }

    .legislation_list_1 {
        top: -145px;
        left: 114px;
        width: 187px;
        display: flex;
        flex-wrap: wrap;
        height: 157px;
    }

    .list_legislation_links {
        margin: 17px 23px 14px 20px;
        width: 284px;
    }

    .list_legislation_links a {
        font-size: 9px;
        line-height: 18px;
        padding-bottom: 10px;
        z-index: 2;
    }

    .landing_footer_login {
        padding: 15px;
    }

    .landing_links {
        width: 100%;
    }

    .wrapper_contacts {
        margin-bottom: 0px;
    }

    .landing_footer_our_contacts {
        padding-left: 10px;
        top: -155px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        background: none;
    }

    .provisions_body_1 {
        margin-top: 43px;
    }

    .landing_footer_legislation {
        height: 209px;
        padding-left: 10px;
    }

    .provisions_pre_title_2 {
        width: 96%;
    }

    .landing_footer_faq {
        top: -67px;
        width: 92%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        background: none;
    }

    .wrapper_description_cost_left_license {
        padding-left: 7px;
        margin:auto;
    }

    .provisions_title {
        height: max-content;
        font-size: 22px;
        line-height: 37px;
        margin-bottom: 20px;
    }

    #root > div.wrapper_faq > div:nth-child(4) > div > div.landing_wrapper_contacts > div.footer_mobile > div {
        display: none;
    }

    #contacts > div.landing_wrapper_contacts > div.footer_mobile > div {
        display: none;
    }

    .provisions_title_2 {
        width: 98%;
    }

    .provisions_body_2 {
        width: 99%;
    }

    .login {
        width: 37px;
        height: 33px;
        position: relative;
        top: 5px;
        left: 203px;

    }

    .title {
        width: 33%;
    }

    .title_service {
        width: 32%;
        left: 0.45%;
    }

    .wrapper_military_registration, .title_law_military_registration, .list_mr_1, .list_mr li, .wrapper_our_work,
    .wrapper_description_cost {
        width: 99%;
    }

    .wrapper_description_mobile {
        top: 171px;
        width: 99%;
        height: 126px;
        font-size: 34px;
        line-height: 124.9%;
        text-align: center;
        mix-blend-mode: normal;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        position: absolute;
        font-style: normal;
        font-weight: bold;
        color: rgba(235, 213, 19, 0.5);
    }

    .landing_main__welcome {
        background: url(assets/images/wrapper-main-bg.png)
    }

    .wrapper_header {
        width: 94%;
        left: 10px;
        top: 15px;
        height: 26px;
    }


    .title, .title_service {
        font-size: 12px;
    }

    .wrapper_description_1 {
        font-size: 34px;
        top: 173px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .wrapper_description_note {
        font-size: 18px;
        top: 314px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 318px;
        line-height: 142.19%;
        height: 104px;
    }

    .license_btn {
        top: 450px;
        width: 299px;
        height: 50px;
    }

    .license_btn div {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 128.69%;
        text-align: center;
        width: 100%;
    }

    .list_description {
        top: 670px;
        height: 247px;
        width: 306px;
    }

    .list_point {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
    }

    .list_description img {
        width: 26px;
        height: 26px;
        margin-right: 15px;
    }

    .possibility_list {
        padding-bottom: 0px;
    }

    .what_is_mr {
        display: none;
    }

    .what_is_mr_mobile {
        display: flex;
        margin: 50px auto 17px auto;
    }

    .title_what_is_mr {
        top: 43px;
        font-size: 22px;
    }

    .list_mr li {
        font-size: 14px;
    }

    .list_mr {
        padding-left: 7px;
    }

    .title_law_military_registration {
        margin: 0px auto 29px auto;
        width: 97%;
        height: 69px;
        font-weight: 500;
        font-size: 15px;
        line-height: 154%;
    }

    .list_mr img {
        width: 13px;
        height: 16px;
        margin-right: 7px;
    }

    .list_mr_1 {
        display: flex;
    }

    .list_mr li:first-child {
        margin-bottom: 27px;
    }

    .list_mr li:nth-child(2) {
        margin-bottom: 12px;
    }

    .list_mr li:nth-child(3) {
        margin-bottom: 14px;
    }

    .list_mr li:nth-child(4) {
        margin-bottom: 35px;
    }

    .footer_military_registration {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: 30px;
        width: 99%;
        height: 69px;
        font-weight: 500;
        font-size: 15px;
        line-height: 154%;
    }

    .title_our_work_mr_img {
        font-size: 22px;
        position: absolute;
        top: 1514px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .wrap_work {
        display: none;
    }

    .wrap_work_mobile {
        display: flex;
    }

    .title_our_work_mr {
        margin: -2px auto 35px auto;
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 154%;
        width: 274px;
        height: 46px;
    }

    .wrapper_work_description_mobile {
        display: flex;
    }

    .title_mode_point_1, .title_mode_point_2 {
        display: flex;
        flex-direction: column;
        width: 99%;
        margin-left: 0px;
    }

    .mode_list_left li {
        list-style-type: none;
    }

    .our_work_mr_img {
        margin: 68px auto 17px auto;
        width: 218px;
        height: 31px;
        display: block;
    }

    .title_mode_point_1_title div, .title_mode_point_2_title div, .mode_list_left li, .mode_list_left a,
    .mode_list_right li, .mode_list_right a {
        font-size: 14px;
    }

    .title_mode_point_1_title div, .title_mode_point_2_title div {
        margin-bottom: 0px;
    }

    .mode_list_left li:first-child, .mode_list_left li:first-child {
        padding-bottom: 55px;
    }

    .title_mode_point_1 img, .title_mode_point_2 img {
        width: 9px;
        height: 16px;
        margin-right: 12px;
    }

    .mode_list_left li:nth-child(2), .mode_list_right li:nth-child(2) {
        margin-bottom: 20px;
    }

    .mode_list_left a, .mode_list_right a {
        text-decoration: underline;
    }

    .mode_list_left li, .mode_list_right li {
        width: 320px;
        height: 44px;
        display: block
    }


    .mode_list_right li:first-child {
        padding-bottom: 15px;
    }

    .wrapper_cost {
        margin-top: -15px;
    }

    .title_cost {
        font-size: 21px;
        margin-left: 74px;
    }

    .cost_img {
        width: 24px;
        height: 24px;
    }

    .wrapper_title_cost {
        top: 13px;
        margin-bottom: -27px;
    }

    .wrapper_description_cost {
        height: 230px;
    }

    .cost_footer {
        width: 98%;
        top: 77px;
    }

    .cost_footer div:first-child {
        padding-bottom: 31px;
    }

    .wrapper_cost {
        background: linear-gradient(0deg, rgba(9, 19, 1, 0.4), rgba(9, 19, 1, 0.4)), url(assets/images/wrapper_cost_mobile.png);
        width: 376px;
        height: 772px;
        background-size:contain;

    }

    .wrapper_description_cost_left_title_license {
        font-size: 16px;
        margin:auto;
    }

    .wrapper_description_cost_left_license div:nth-child(2) {
        padding-bottom: 40px;
    }

    .wrapper_description_cost_left_license div, .cost_footer div {
        font-size: 14px;
    }

    .wrapper_description_cost_left_license div {
        padding-bottom: 19px;
    }

    .wrapper_description_cost {
        flex-direction: column;
        width: 99%;
    }

    .landing_questions {
        font-size: 22px;
        top: 50px;
        width: 100%;
    }

    .landing_questions_connect {
        font-size: 16px;
        top: 64px;
        width: 100%;
    }

    .form_control_landing input, .form_control_landing textarea {
        width: 97%;
        height: 50px;
    }

    .form_control_landing textarea {
        margin-bottom: 20px;
    }

    .top_block_addresses_contacts {
        display: flex;
    }

    .landing_wrapper_contacts {
        width: 99%;
        top: 103px;
        display: flex;
        flex-direction: column;
    }

    .form_control_landing {
        margin-left: 10px;
        width: 98%;
    }

    .btn_call {
        width: 97%;
        height: 44px;
        margin-bottom: 70px;
        background: #DBAA14;
        border: 1px solid;
        margin-right: 0px;
    }

    .list_point_2 {
        width: 277px;
    }

    .wrapper_addresses {
        width: 375px;
        height: 700px;
        background: linear-gradient(0deg, rgba(9, 19, 1, 0.7), rgba(9, 19, 1, 0.7)), url(assets/images/BG.png);
    }

    .top_block_addresses span{
        font-size: 16px;
        text-align: left;
    }
     .top_block_addresses_title_2 {
        font-size: 16px;
    }

    .top_block_addresses_contacts {
        margin-bottom: 30px;
        margin:0px auto 20px auto;
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        color: #EAB40E;
        width: fit-content;
    }

    .down_block_addresses_title {
        font-size: 14px;
        width: 317px;
        height: 324px;
        top: 21px;
    }

    .top_block_addresses {
        margin-top: 28px;
        margin-left: 11px;
        width: 96%;
    }

    .landing_footer {
        background: none;
        top: -255px;
        position: relative;
        width:95%;
        left: 50%;
        transform: translateX(-50%);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        border-right: none;
        border-left: none;
    }

    .footer_line {
        display: none;
    }

    .footer_title {
        font-size: 14px;
        top: 15px;
        left: -55px;
        margin-bottom: 19px;
    }

    .footer_license, .wrapper_description {
        display: none;
    }

    .footer_license_mobile {
        display: block;
        font-size: 13px;
        color: white;
        font-family: Rubik;
        margin-left: -1px;
        position: relative;
        top: 3px;
        left: -1px;
    }

    .landing_links {
        width: 330px;
        height: 92px;
        top: 13px;
        left: -1px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: start;
    }

    .vectors {
        display: none;
    }

    .landing_links_links, .activeLink_low {
        width: 145px;
        font-size: 10px;
    }

    .menu_wrapper_mobile, .burger-menu-mobile {
        display: block;
    }

    .menu_wrapper, .call, .vectors, .wrapper_work_description, .footer_btn, .sign_in_btn, .footer_license,
    .mask, .footer_desktop {
        display: none;
    }

    .mode_list_left li:nth-child(3), .mode_list_right li:nth-child(3) {
        width: 320px;
    }

    .footer_mobile {
        display: flex;
        height: 0px;
    }
}

@media (min-width: 361px) {

    #contacts > div.landing_wrapper_contacts > div.footer_mobile > div {
        display: none;
    }

    #root > div.wrapper_faq > div:nth-child(4) > div > div.landing_wrapper_contacts > div.footer_mobile > div {
        display: none;
    }

    .provisions_title_2 {
        width: 98%;
    }

    .provisions_body_2 {
        width: 99%;
        margin-bottom: 82px;
    }
}

@media (max-width: 565px) {
    .provisions_pre_title_4, .provisions_pre_title_3 {
        left: 10px;
    }
}

@media (max-width: 456px) {
    .provisions_title_2 {
        font-size: 19px;
        line-height: 33px;
    }

    .title_offer_1 {
        height: max-content;
    }
}
@media (max-width: 689px) {
    .wrapper-calculator-form{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .wrapper-calculator-form select, .wrapper-calculator-form input{
        height: 40px;
        width: 200px;
    }
    .wrapper-calculator-form button{
        width: 284px;
    }
}

@media (max-width: 1037px) and (min-width: 723px) {
    .provisions_pre_title_2 {
        left: 10px;
    }

    .provisions_title_1 {
        width: 98%;
    }
}

@media (max-width: 723px) {

    .provisions_pre_title_2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        left: 10px;
        width: 97%;
    }

    .provisions_title_1 {
        display: flex;
        flex-wrap: wrap;
        width: 98%;
        font-size: 23px;
        line-height: 41px;

    }
}

@media (min-width: 320px) and (max-width: 368px) {
    #scrollable-auto-tabpanel-0 > div > p > p:nth-child(1) > a {
        width: 98%;
        display: inline-block;
    }
}
@media (min-width: 320px) and (max-width: 335px) {
    .wrapper_work_mobile{
        height: 4322px;
    }
    .wrapper_cost_mg {
        margin: 1368px auto 0 auto;
    }
    .title_mode_point_1 div, .title_mode_point_2 div{
        font-size: 17px;
    }

}
@media (min-width: 335px) and (max-width: 360px) {
    .details_block_text{
        top: 1815px;
        height: 5126px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }
}

@media (min-width: 360px) and (max-width: 411px) {

    .wrapper_work_mobile{
        height: 4322px;
    }
}
@media (min-width: 411px) and (max-width: 440px)  {
    .details_block_text{
        top: 1918px;
        height: 3858px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }

}
@media (min-width: 440px) and (max-width: 475px)    {
    .details_block_text{
        top: 1918px;
        height: 3675px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }

}
@media (min-width: 475px) and (max-width: 548px)  {
    .details_block_text{
        top: 1918px;
        height: 3445px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }

}
@media (min-width: 548px) and (max-width: 578px) {
    .details_block_text{
        top: 1918px;
        height: 3091px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }

}
@media (min-width: 578px) and (max-width: 700px) {

    .wrapper_work_mobile{
        height: 4322px;
    }

}
@media (min-width: 700px) and (max-width: 764px) {

    .wrapper_work_mobile{
        height: 4322px;
    }

}
@media (min-width: 764px) and (max-width: 900px) {
    .details_block_text{
        top: 1815px;
        height: 2659px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }
}
@media (min-width: 900px) and (max-width: 999px) {
    .details_block_text{
        top: 1815px;
        height: 2321px;
    }
    .wrapper_work_mobile{
        height: 4322px;
    }
    .wrapper_cost_mg {
        margin: -1611px auto 0 auto;
    }
}




    @media (min-width: 320px)  {

    .title_mode_point_2_title{
        font-weight: bold;
    }
    .mode_list_left li:nth-child(3), .mode_list_right li:nth-child(3){
        text-align: center;
    }
    .title_mode_point_1_title, .title_mode_point_2_title{
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 700px) {
    .landing_footer_faq {
        height: 203px;
    }
}

@media (max-width: 600px) {
    #scrollable-auto-tab-1 > span.MuiTab-wrapper, #scrollable-auto-tab-0 > span.MuiTab-wrapper,
    #scrollable-auto-tab-2 > span.MuiTab-wrapper, #scrollable-auto-tab-3 > span.MuiTab-wrapper,
    #scrollable-auto-tab-4 > span.MuiTab-wrapper, #scrollable-auto-tab-5 > span.MuiTab-wrapper,
    #scrollable-auto-tab-6 > span.MuiTab-wrapper, #scrollable-auto-tab-7 > span.MuiTab-wrapper {
        font-size: 8px;
    }

    .provisions_title {
        font-size: 22px;
        line-height: 29px;
        display: flex;
        flex-wrap: wrap;
    }

}

@media (max-width: 1300px) {
    .provisions_title, .provisions_body {
        width: 98%;
    }

}

@media (max-width: 943px) {
    .provisions_pre_title {
        left: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 97%;
    }
}

@media (max-width: 697px) {
    provisions_title {
        height: max-content;
    }
}

@media (max-width: 763px) {
    .provisions_pre_title_1 {
        left: 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 97%;
    }

    .provisions_title {
        width: 98%;
    }

    .title_offer {
        font-size: 22px;
        line-height: 47px;
        display: flex;
        flex-wrap: wrap;
        height: max-content;
    }
}

@media (max-width: 1316px) {
    .title_offer, .title_offer_1 {
        width: 98%;
    }

    .body_offer {
        width: 98%;
    }
}
@media (min-width: 558px) and (max-width: 1049px) {
    .titles_form{
        height: 180px;
    }
}
@media (min-width: 360px) and (max-width: 558px) {
    .titles_form{
        height: 252px;
    }
}
@media (min-width: 320px) and (max-width: 360px) {
    .titles_form{
        height: 195px;
    }
}
@media (max-width: 1049px) {
    .registration_, .titles_form {
        width: 98%;
    }
    .form-control {
        height: max-content;
        border: 0px;
        top: 132px;
    }

    .wrapper_contacts {
        margin-top: 114px;
    }

}
@media  (max-width: 999px) {

    .details_block__{
        display: none;
    }
}
@media (max-width: 835px) {
    .form-control {
        width: 98%;
        margin: 0 auto;
    }

    .form-control input, .form-control select {
        width: 100%;
    }

}

@media (min-width: 1165px) and  (max-width: 1320px) {
    .legislation_list, .legislation_list_1 {
        left: 378px;
    }
}

@media (min-width: 999px) and  (max-width: 1165px) {
    .legislation_list, .legislation_list_1 {
        left: 325px;
    }
}

@media (max-width: 1500px) {
    .link_offer_pdf {
        left: 6%;
    }
}

@media (max-width: 338px) {
    .title {
        width: 36%;
    }

    .title_service {
        width: 35%;
        left: 0.45%;
    }

    .list_point, .list_point_1, .list_point_2, .list_point_3 {
        font-size: 13px;
    }

    .title_cost {
        margin-left: 55px;
        margin-right: 55px;
    }

    .list_point_2 {
        width: 268px;
    }

    .down_block_addresses_title {
        width: 96%;
        left: 141px;
    }

    .landing_links {
        width: 100%;
    }

    .landing_footer {
        padding-left: 13px;
    }

    .mode_list_left li, .mode_list_right li {
        width: 100%;
    }

    .mode_list_left li:nth-child(3), .mode_list_right li:nth-child(3) {
        width: 100%;
    }
}
@media (min-width: 999px) and (max-width: 1470px) {
    .details_block_text, .details_block_text_more {
        position: static;
        width: 96%;
        left: 40px;
        font-size: 15px;

        overflow: auto;
        background: #EBFFCD;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 10px;
    }
}
@media (min-width: 1470px) {
    .details_block_text, .details_block_text_more {
        position: static;
        width: 96%;
        left: 40px;
        font-size: 19px;

        overflow: auto;
        background: #EBFFCD;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 10px;
    }
}
/*div,span,section,button,ul,li,p,a,input,textarea, img {*/
/*    border: 1px solid red;*/
/*}*/

.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 40px;
    color: white;
    transition: color 0.2s;
}

.bm-item-list a, .menu-item_low {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.menu-item_activeLink {
    cursor: pointer;
    color: #DBAA14;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
}

.menu-item_low {
    cursor: pointer;
}

.bm-item:hover {
    color: #DBAA14;
    text-decoration: underline;
}


.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 20px;
    top: 23px;
}

.bm-burger-bars {
    background: #16a8ce;
    width: 45px;
    height: 2px !important;
    padding-bottom: 2px;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
    right: 11px;
    top: 25px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu {
    background: rgba(2, 11, 0, 0.98);
    padding: 80px 87px 10px 20px;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: white;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
.wrapper_cost_pdf button{
    height: 57px;
    border: 1px solid #DBAA14;
    box-sizing: border-box;
    border-radius:4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 144%;
    background: none;
    text-align: center;
    color: #FFFFFF;
}
.paid_pdf {
    width: 252px;
}
.paid {
    width: 281px;
}
.wrapper_cost_pdf{
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: 135px auto 0px auto;
}
.low_mobile_list li {
list-style-type: none;
line-height: 5px;
margin-bottom: 10px;
}

.low_mobile_list a {
font-weight: 300;
font-size: 12px;
}

.low_mobile_list a:hover {
color: #DBAA14;
}

.low_mobile_list {
padding-left: 21px;
margin-top: -32px;
}

.bm-item-list button:hover {
color: #DBAA14;
}

.bm-item-list button {
cursor: pointer;
height: 53px;
width: 250px;
background: black;
color: white;
border: 1px solid #DBAA14;
box-sizing: border-box;
border-radius: 4px;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
text-align: center;
text-transform: uppercase;
}

.bm-item-list a:nth-child(5) {
margin-bottom: 63px;
}
.mainMode_mobile{
width: 100%;
height: 2267px;
background: #EBFFCD;
border: 1px solid rgba(0, 0, 0, 0.1);
margin-top: 10px;
display: flex;

}
@media (min-width: 320px) and (max-width: 377px) {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 10px;
}
}
@media (min-width: 320px) and (max-width: 350px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 9px;
}
}
@media (min-width: 350px) and (max-width: 426px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 10px;
}
}
@media (max-width: 1080px) {
.top_block_addresses_title_3{
    flex-direction: column;
    margin: 5px auto 23px auto;
    width: max-content;
}
    .top_block_addresses_title_3 span{
      padding-bottom: 8px;
    }
}


@media (min-width: 426px) and (max-width: 494px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 11px;
}
}
@media (min-width: 494px) and (max-width: 600px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 12px;
}
}
@media (min-width: 600px) and (max-width: 674px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 13px;
}
}
@media (min-width: 674px) and (max-width: 754px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 14px;
}
}
@media (min-width: 754px) and (max-width: 868px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 15px;
}
}
@media (min-width: 868px) and (max-width: 999px) {
.title_mode_point_2 div:first-child{
    font-weight: normal;
    font-size: 16px;
}
}
@media (min-width: 377px) and (max-width: 441px) {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 11px;
}
}
@media (min-width: 441px) and (max-width: 516px)  {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 12px;
}
}
@media (min-width: 516px) and (max-width: 593px)   {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 13px;
}
}
@media (min-width: 593px) and (max-width: 697px)    {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 14px;
}
}
@media (min-width: 697px)  and (max-width: 797px)  {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 15px;
}
}
@media (min-width: 797px) and (max-width: 889px)  {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 16px;
}
}
@media (min-width: 889px) and  (max-width: 965px) {
.title_mode_point_1 div:first-child{
    font-weight: normal;
    font-size: 17px;
}
}
@media(min-width: 965px){
.title_mode_point_1 div:first-child, .title_mode_point_2 div:first-child{
    font-weight: normal;
}
}

@media (max-width: 999px)  {
.details_block_text, .details_block_text_more{
    position: static;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 2378px;
}
    .top_block_addresses_title {
        padding-bottom: 0px;
    }

.title_mode_point_22 {
    margin-top: 2370px;
}
.body_landing_wrapper_mobile{
    height: 4839px;
}

}
@media (max-width: 361px) {
.wrapper_description_cost_left, .wrapper_description_cost_right {
    width: 100%;
}
.titles_form div {
    font-size: 17px;
}
/*.wrapper_cost_mg {
    margin: -1181px auto 0 auto;
}*/
.wrapper_cost_mg_1 {
    margin: 2337px auto 0 auto;
}
}
/*@media (min-width: 361px) and (max-width: 999px) {
.wrapper_cost_mg {
    margin: -1115px auto 0 auto;
}
}*/
@media (min-width: 361px) and (max-width: 700px) {
.wrapper_cost_mg_1 {
    margin: 2351px auto 0 auto;
}
}
@media (min-width: 700px) and (max-width: 999px) {
.wrapper_cost_mg_1 {
    margin: 2365px auto 0 auto;
}
}
@media (max-width: 525px)  {
.mode_list_left li:nth-child(3), .mode_list_right li:nth-child(3){
    width: 100%;
}
}
@media (max-width: 360px)  {
.cost_footer div:first-child {
    font-size: 13px;
}
}
@media (min-width: 361px) and (max-width: 453px)  {
.cost_footer div:first-child , .cost_footer div{
    font-size: 12px;
    padding:7px
}
.wrapper_description_cost_left_title_license, .wrapper_description_cost_left_license div {
    font-size: 13px;
}
.wrapper_description_cost_left_license div {
    padding-left: 13px;
}
.cost_footer {
    top: 3px;
}
.wrapper_description_cost {
    top: 25px;
}
}
@media (min-width: 453px) and  (max-width: 600px)  {
.cost_footer div:first-child {
    font-size: 13px;
    padding:7px
}
.wrapper_description_cost_left_title_license, .wrapper_description_cost_left_license div {
    font-size: 14px;
}
.wrapper_description_cost_left_license div {
    padding-left: 14px;
}
.cost_footer {
    top: 37px;
}
.wrapper_description_cost {
    top: 25px;
}
}
@media (min-width: 601px) and (max-width: 1288px) {
.cost_footer div:first-child, .cost_footer div {
    font-size: 12px;
    padding: 7px
}
}
@media (min-width: 601px) and (max-width: 1210px) {
.cost_footer {
    top: 53px;
}
.wrapper_description_cost {
    top: 35px;
}
}
@media (min-width: 1601px) and (max-width:1790px) {
.landing_links_links {
    font-size: 12px;
}
}
@media (max-width: 1381px) and (min-width:1184px) {
.mask {
    top: -27px;
    left: 593px;
    height: 91px;
}
}
@media (max-width: 700px) and (min-width:430px) {
.down_block_addresses_title {
    width: 100%;
    font-size: 10px;
    top: 12px;

}
}
@media (max-width: 430px) and (min-width:360px) {
.down_block_addresses_title {

    
    font-size: 10px;
    top: 4px;
    width: 100%;
}
.top_block_addresses_title_2 span {
    font-size: 11px;
}
.top_block_addresses_title_3{
    margin-top: 6px;
}
.top_block_addresses_contacts {
    font-size: 15px;
}
.top_block_addresses_title {
    font-size: 13px;
    padding-bottom: 5px;
}
}
@media (max-width: 890px) {
    /*.wrapper_cost_pdf{*/
    /*    justify-content: center;*/
    /*    width: 100%;*/
    /*}*/
    .wrapper_cost_pdf button{
        font-size: 11px;
    }
    .paid, .paid_pdf{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 150px
    }
}
@media (max-width: 1715px) and (min-width: 999px) {
    .footer_license{
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    .paid_logos{
        margin-right: 20px;
        margin-left: 20px;
        height: 55px;
    }
}
@media (max-width: 999px) and (min-width: 700px) {
    .footer_license{
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    .paid_logos{
        margin-right: 25px;
        margin-left: 25px;
        height: 46px;
    }
}
@media(min-width: 361px) and (max-width: 700px) {
    .paid_logos_mobile{
        display: block;
        width: 300px;
        margin: 0 auto;
        height: 30px;
    }
}
@media(max-width: 360px){
    .paid_logos_mobile{
        display: block;
        width: 260px;
        margin: 0 auto;
        height: 30px;
        margin-top: 15px;
    }
    
    .title-example{
        font-size:28px;
    }
    .mode_list_left{
        padding-left:20px;
    }
    .mode_list_right{
        padding-left:20px;
    }
    .wrapper_our_work{
        margin: 0px auto 15px auto;
    }
}

@media (max-width: 495px) {
   .title_recv{
     width: 97%;
   }
    .down_block_addresses_title {
        height: 181px
    }
}

.forget-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forget-modal span {
    padding-bottom: 10px;
    font-size: 17px;
}
.forget-modal input {
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 15px;
}

@media (max-width: 1780px)  {
    .wrapLicenceBtns{
        width: 90%;
    }
}
@media (max-width: 1747px) {
   .wrap-description-licence{
       width: 514px;
   }
}
@media (max-width: 1370px) and (min-width: 1285px) {
    .wrapLicenceBtns{
        width: 95%;
    }
    .wrap-description-licence {
        width: 514px;
    }
}
@media (max-width: 1395px) {
    .wrapLicenceBtns{
        
        
        width: 100%;
        top: 639px;
    }

    .wrapLicenceBtns-child {
        width: 100% !important;
        margin-bottom: 25px;
    }
  
    .wrapLicenceBtns div span{
        margin-right: auto !important;
        margin-left: auto !important;
        margin-bottom: 15px;
    }

    .landing_main__welcome {
        height:1000px;
        background-size: cover;
        min-width:375px;
    }
    .wrap-carousel{
        top: 949px;
    }

}

@media (min-width: 590px) and  (max-width: 715px) {
    .wrap-description-licence {
        font-size: 13px;
    }
}
@media (max-width: 590px) {
    .wrap-description-licence {
        font-size: 13px;
    }
    .wrapLicenceBtns div span{
width: 93%;
    }
    .wrap-description-licence{
        width: 100%;
    }
}


@media (max-width: 450px) {

    .wrap-description-licence {
        font-size: 10px;
        height: 65px;
        
    }
    .wrapLicenceBtns {
        top: 670px;
    }
    .licence-items {
        width: 95%;
    }
    .licence-items img{
        width: 10px;
        margin-right: 5px;
    }
    .licence-items-two img{
        width: 10px;
        margin-right: 5px;
    }
    .licence-items-two {
        margin:0;
    }
}
@media(min-width: 701px) and (max-width: 895px) {
    .wrapLicenceBtns {
        top: 678px;
    }
    .wrapper_description_note{
        top: 320px;
    }
}

@media(min-width: 895px)   {
    .wrapLicenceBtns {
        margin-top: 40px;
    }
}
@media(min-width: 1895px) and (min-width: 1700px)   {
    .wrapper_military_registration{
        width: 83%;
    }
}
@media(min-width: 1500px) and (max-width: 1700px)   {
    .wrapper_military_registration{
        width: 89%;
    }
}
@media(min-width: 1200px) and (max-width: 1500px)   {
    .wrapper_military_registration{
        width: 99%;
    }
    .wrapper-why-us-comp-item span{
        font-size: 14px;
    }
}
@media(max-width: 1200px)   {
    .wrapper-why-us-comp{
        flex-direction: column;
        align-items: center;
    }
    .wrapper_military_registration div{
        margin-right: 0px !important;
    }
    .wrapper-why-us-comp-item span{
        font-size: 16px;
    }
    .wrapper-why-us-comp-item{
        width: 603px;
    }
}
@media(max-width: 625px) {
    .wrapper-why-us-comp-item{
        width: 85%;
    }
}
@media(max-width: 1820px) and (min-width: 1748px) {
  .wrap-description-licence{
      width: 576px;
  }
}
@media(max-width: 361px)  {
    .what_is_mr_mobile{
        display: flex;
        justify-content: center;
    }
    .wrap_work_mobile {
        margin-top: -84px;
    }
}
@media(max-width: 1682px) and (min-width: 1500px) {
    .wrapper_description_cost{
        width: 80%;
    }
}
@media(max-width: 1500px) and (min-width: 1300px) {
    .wrapper_description_cost{
        width: 91%;
    }
}
@media(max-width: 1300px)  {
    .wrapper_description_cost{
        width: 99%;
    }
}
@media(max-width: 1000px)  {

.po-images{
    justify-content: center;
}
    .send-form-calc input::placeholder{
        font-size: 10px;
    }
    .calc-results, .results-calc-title, .send-form-calc input, .title-calc-window{
        width: 97%;
    }
    .wrapper_description_cost{
        width: 95%;
    }
    .wrapper_description_cost_left, .wrapper_description_cost_right{
        flex-direction: column;
    }
    .link_offer_pdf {
        font-size: 15px;
    }
    .wrapper-calculator-form{
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .wrapper-calculator{
        width: 85%;
    }
    .wrapper-calculator-form select, .wrapper-calculator-form input{
        margin-bottom: 27px;
        width: 195px;
    }
    .wrapper-calculator-form select, .wrapper-calculator-form input::placeholder{
        font-size: 10px;
    }
}

@media(max-width: 614px) {
    .wrapper_description_cost_left, .wrapper_description_cost_right{
      width: 78%;
    }
}
@media(max-width: 750px) {
    .calc-results div:nth-child(1), .calc-results div:nth-child(2), .calc-results div:nth-child(3){
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .send-form-calc{
        flex-direction: column;
        align-items: center;
    }
    .send-form-calc input {
        margin-bottom: 15px;
    }
    .send-form-calc button{
        width: 50%;
    }
}
@media(max-width: 886px) {
    .wrapper-calculator {
        margin-bottom: 215px;
    }
}
@media(max-width: 531px) {
    .wrapper-calculator {
        margin-bottom: 340px;
    }
}
@media (max-width: 1345px) and (min-width: 1000px) {
    .wrapper-calculator-form input::placeholder{
        font-size: 13px;
    }
}
@media (max-width: 1557px) and (min-width: 1401px) {
    .wrapper-calculator-form input::placeholder{
        font-size: 14px;
    }
}
.red {
    background-color: red;
}