.container{
    width:315px;
    height:794px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.title {
    font-family: 'Rubik';
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
}
.dataSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.getRank{
    display: flex;
    align-items: baseline;
}
.wrapYear{
    display: flex;
    align-items: baseline;
}
.wrapper{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}
.lineSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    margin: 286px 10px;
}
/*.voucher_style input ::before, input ::after, input{*/
/*    padding: 0px!important;*/
/*}*/
